import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../shared/components/badge/badge';
import Button from '../../../shared/components/button/button';
import CopyableItem from '../../../shared/components/copyable-item/copyable-item';
import Icon from '../../../shared/components/icon/icon';
import Menu, { MenuAction } from '../../../shared/components/menu/menu';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import useCopyToClipboard from '../../../shared/hooks/use-copy-to-clipboard';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { exportFile } from '../../../shared/utils/file-utils';
import { ReactComponent as MagicWandIcon } from '../../../assets/icons/magic-wand.svg';
import { ReactComponent as MoreMenuIcon } from '../../../assets/icons/menu-more.svg';
import { ReactComponent as EthereumLogo } from '../../../assets/logos/ethereum-logo2.svg';
import { ReactComponent as CosmWasmLogo } from '../../../assets/logos/cosm-wasm-logo.svg';
import { formatNumber } from '../../../shared/utils/number-utils';
import { Network } from '../../network/network-types';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import RollappSummary from '../rollapp-summary/rollapp-summary';
import { CreateRollappStep } from './create-rollapp-page/types';
import { ManageRollappsContextProvider, useManageRollapps } from './manage-rollapps-context';
import RollappStatusBadge from '../rollapp-status/badge/rollapp-status-badge';
import './manage-rollapps-page.scss';

const ManageRollappsPage: React.FC = () => {
    const navigate = useNavigate();
    const copyToClipboard = useCopyToClipboard();
    const { isTablet } = useWindowSize();
    const { managedRollapps, loading } = useManageRollapps();

    const haveRollapps = useMemo(() => !loading && managedRollapps?.length, [ loading, managedRollapps ]);

    const renderBottomBar = (): ReactElement | undefined => {
        if (!loading && !managedRollapps?.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    const renderRollappNameColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn className='name-column'>
                <RollappSummary rollapp={rollapp} />
            </TableColumn>
        );
    };

    const renderRollappIdColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn nowrap>
                <CopyableItem text={rollapp.chainId} dataName='RollApp ID' tooltipPlacement='top-start' />
            </TableColumn>
        );
    };

    const renderRollappVMColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn>
                <Icon className='vm-logo' iconColorMode='original'>{rollapp.evmType === 'EVM' ? <EthereumLogo /> : <CosmWasmLogo />}</Icon>
                {rollapp.evmType}
            </TableColumn>
        );
    };

    const renderRollappStatusColumn = (rollapp: Network): ReactElement => {
        return <TableColumn className='status-column' nowrap><RollappStatusBadge status={rollapp.status} /></TableColumn>;
    };

    const renderCreationStepColumn = (rollapp: Network): ReactElement => {
        let step: CreateRollappStep | undefined = undefined;
        if (!rollapp.genesisChecksum) {
            step = 'Tokenomics';
        } else if (!rollapp.initialSequencer) {
            const currentStep = localStorage.getItem(`current-step-${rollapp.chainId}`); // todo: temporary
            step = (currentStep ? JSON.parse(currentStep) as CreateRollappStep : undefined) || 'Set Operator';
        }
        return (
            <TableColumn nowrap>
                {!step ? <span className='secondary-text creation-step-done'>Done</span> :
                    <Badge label={step} rounded color={getCssVariableValue('--cream-very-dark-rgb').split(',').map(Number)} />}
            </TableColumn>
        );
    };

    const renderAppsColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn>
                {rollapp.apps?.length ? formatNumber(rollapp.apps.length) : <span className='secondary-text'>No Apps</span>}
            </TableColumn>
        );
    };

    const renderRollappActionsColumn = (rollapp: Network): ReactElement => {

        return (
            <TableColumn className='actions-column' align='right'>
                <Button
                    size='small'
                    className='manage-rollapp-action'
                    buttonType='secondary'
                    onClick={() => navigate(`/rollapps/manage/create/${rollapp.chainId}`)}
                >
                    Manage
                </Button>
                <Menu closeWhenScroll trigger={<Button buttonType='icon'><MoreMenuIcon /></Button>}>
                    <MenuAction onClick={() => navigate(`/rollapps/manage/create/${rollapp.chainId}`)}>Manage</MenuAction>
                    <MenuAction
                        disabled={!rollapp.genesisUrl}
                        onClick={() => rollapp.genesisUrl && copyToClipboard(rollapp.genesisUrl, 'genesis URL')}
                    >
                        Copy genesis url
                    </MenuAction>
                    <MenuAction
                        disabled={!rollapp.genesisUrl}
                        onClick={() => rollapp.genesisUrl && exportFile(rollapp.genesisUrl, `${rollapp.chainId}-genesis.json`)}
                    >
                        Download genesis
                    </MenuAction>
                </Menu>
            </TableColumn>
        );
    };

    const renderRollappsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Name</TableColumn>
                <TableColumn>RollApp ID</TableColumn>
                <TableColumn nowrap>Virtual Machine</TableColumn>
                <TableColumn>Status</TableColumn>
                <TableColumn>Creation Step</TableColumn>
                <TableColumn>Apps</TableColumn>
                <TableColumn></TableColumn>
            </TableRow>
        );
    };

    const renderRollappRow = (rollapp: Network): ReactElement => {
        return (
            <TableRow key={rollapp.chainId} onSelect={() => navigate(`/rollapps/manage/create/${rollapp.chainId}`)}>
                {renderRollappNameColumn(rollapp)}
                {renderRollappIdColumn(rollapp)}
                {renderRollappVMColumn(rollapp)}
                {renderRollappStatusColumn(rollapp)}
                {renderCreationStepColumn(rollapp)}
                {renderAppsColumn(rollapp)}
                {renderRollappActionsColumn(rollapp)}
            </TableRow>
        );
    };

    return (
        <div className='page manage-rollapps'>
            <PathNav>
                <PathNavItem label='RollApps' url='/rollapps' />
                <PathNavItem label='Manage' />
            </PathNav>

            <div className='create-first-rollapp-section'>
                <img
                    src={require('../../../assets/images/header-background.jpg')}
                    alt='create first rollapp background'
                    className='create-first-rollapp-background'
                />

                <div className='create-first-rollapp-content'>
                    <h2>Deploy {haveRollapps ? 'a new' : 'your first'} RollApp</h2>
                    <p className='description'>Launch your own lightning-fast non custodial rollup in minutes!</p>
                    <Button className='start-button' onClick={() => navigate('/rollapps/manage/create')}>Start ⟶</Button>
                </div>
            </div>

            <div className='rollapps-table-actionbar'>
                <h5 className='table-header'>Managed RollApps</h5>
                <Button
                    tooltipPlacement='top'
                    tooltip={isTablet ? 'Create new RollApp' : ''}
                    className='manage-rollapp-button'
                    onClick={() => navigate('/rollapps/manage/create')}
                >
                    <MagicWandIcon /><span className='create-rollapp-button-text'>Create New RollApp</span>
                </Button>
            </div>

            <div className='managed-rollapps-table-container'>
                <Table indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderRollappsHeaderRow()}
                    {managedRollapps?.map(renderRollappRow)}
                </Table>
            </div>
        </div>
    );
};

const ManageRollappsPageWithContext = () =>
    <ManageRollappsContextProvider><ManageRollappsPage /></ManageRollappsContextProvider>;

export default ManageRollappsPageWithContext;
