import React from 'react';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAmm } from '../../../amm/amm-context';
import { AnalyticsState } from '../../../analytics/analytics-state';
import { getMainCurrency, getMaxDenomAmount } from '../../../currency/currency-service';
import { Network } from '../../network-types';
import { NetworksAnalytics } from '../analytics/network-analytics-types';
import AreaChart from './area-chart/area-chart';
import { SelectableChartProps } from './chart-container/chart-container';

interface TotalTvlChartProps extends SelectableChartProps {
    network: Network;
    state: AnalyticsState<NetworksAnalytics>;
    className?: string;
}

export const TotalTvlChart: React.FC<TotalTvlChartProps> = ({ network, state, className, ...selectableChartProps }) => {
    const { ammState } = useAmm();
    const mainCurrency = getMainCurrency(network);
    return (
        <AreaChart
            {...selectableChartProps}
            label={network.type === 'Hub' ? 'TVL' : 'TVB'}
            na={!state.analytics?.totalSupply}
            valueLabel='Amount'
            info={network.type === 'Hub' ?
                `IBC tokens + ${mainCurrency.displayDenom} in liquidity pools` :
                'Total amount of IBC tokens bridged to the RollApp'}
            loading={state.analytics === undefined && (state.loading || ammState.paramsLoading)}
            data={state.analytics?.totalSupply}
            formatValue={(amount) => {
                const value = ammState.params ? getMaxDenomAmount(amount, ammState.params.vsCoins.currency) : 0;
                return formatPrice(value, undefined, { notation: 'compact' });
            }}
            fetchComparableValues={(item) => item.tvl || 0}
            className={className}
        />
    );
};

export default TotalTvlChart;
