import React, { ReactNode, useMemo } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import Spinner from '../../../../shared/components/spinner/spinner';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { getStatusName } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';
import './ibc-status-badge.scss';

interface IbcStatusBadgeProps {
    transfer: IbcTransferDetails;
    showBaseStatus?: boolean;
    getTooltipInfo?: () => string | undefined;
}

const IbcStatusBadge: React.FC<IbcStatusBadgeProps> = ({ transfer, showBaseStatus, getTooltipInfo }) => {
    const { isMobile } = useWindowSize();

    const statusColor = useMemo((): (number[] | undefined) => {
        switch (transfer.baseStatus) {
            case 'Success':
                return getCssVariableValue('--light-green-rgb').split(',').map(Number);
            case 'Failed':
                return getCssVariableValue('--red-rgb').split(',').map(Number);
        }
        return getCssVariableValue('--orange-rgb').split(',').map(Number);
    }, [ transfer.baseStatus ]);

    const renderLabel = (): ReactNode => {
        if (!showBaseStatus) {
            return getStatusName(transfer.status);
        }
        if (transfer.baseStatus === 'Pending') {
            return <><Spinner className='status-spinner' size='xs' /> {transfer.baseStatus}</>;
        }
        return transfer.baseStatus;
    };

    return (
        <Badge
            className='ibc-status-badge'
            size={isMobile ? 'small' : 'medium'}
            label={renderLabel()}
            color={statusColor}
            info={getTooltipInfo?.()}
        />
    );
};

export default IbcStatusBadge;
