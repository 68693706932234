import classNames from 'classnames';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { getShortDateTimeString } from '../../../shared/utils/date-utils';
import { formatPrice } from '../../../shared/utils/number-utils';
import { getShortenedAddress } from '../../../shared/utils/text-utils';
import { useAsset } from '../../asset/asset-context';
import DisplayNameWithFuture from '../../currency/display-name-with-future/display-name-with-future';
import { getCurrencyLogoPath, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount, CUSTOM_BASE_DENOM_PREFIX_REGEX } from '../../currency/currency-types';
import { useDymns } from '../../dymns/dymns-context';
import { IbcTransferDetails } from '../../ibc-transfer/ibc-status/ibc-status-types';
import { useNetwork } from '../../network/network-context';
import { Network } from '../../network/network-types';
import './balances-list.scss';

interface AccountBalancesProps {
    balances: CoinsAmount[] | IbcTransferDetails[];
    onBalanceClick?: (balance: CoinsAmount) => void;
    loading?: boolean;
    className?: string;
    header?: ReactNode;
    sortByValue?: boolean;
}

const BalancesList: React.FC<AccountBalancesProps> = ({ balances, onBalanceClick, header, loading, className, sortByValue }) => {
    const navigate = useNavigate();
    const { getAssetLink } = useAsset();
    const { getTokenPrice, assets } = useAsset();
    const { getNetwork, toHubCoins } = useNetwork();
    const { dymnsState } = useDymns();

    const onAssetClick = useCallback((balance: CoinsAmount, transfer?: IbcTransferDetails) => {
        const url = transfer ? `/ibc/status/${transfer.id}` : getAssetLink(balance);
        if (window.location.pathname.includes('/connect')) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
        onBalanceClick?.(balance);
    }, [ getAssetLink, navigate, onBalanceClick ]);

    const fetchCoinsAndTransfer = useCallback((balance: CoinsAmount | IbcTransferDetails): { coins: CoinsAmount, transfer?: IbcTransferDetails } => {
        const balanceAsTransfer = balance as IbcTransferDetails;
        if (balanceAsTransfer.coins) {
            return { coins: balanceAsTransfer.coins, transfer: balanceAsTransfer };
        }
        return { coins: balance as CoinsAmount };
    }, []);

    const balancesInfo = useMemo(() => {
        const list = balances.map((balance) => {
            const { coins, transfer } = fetchCoinsAndTransfer(balance);
            const network = getNetwork(coins.networkId) as Network;
            const showNetworkDomain = Boolean(balances?.some((otherBalance) => {
                const { coins: otherCoins } = fetchCoinsAndTransfer(otherBalance);
                return otherCoins.currency.displayDenom.toLowerCase() === coins.currency.displayDenom.toLowerCase() &&
                    coins.networkId !== otherCoins.networkId;
            }));
            const showBaseDenom = !showNetworkDomain && Boolean(balances?.some((otherBalance) => {
                const { coins: otherCoins } = fetchCoinsAndTransfer(otherBalance);
                return otherCoins.currency.displayDenom.toLowerCase() === coins.currency.displayDenom.toLowerCase() &&
                    coins.currency.baseDenom !== otherCoins.currency.baseDenom;
            }));
            const price = getTokenPrice(coins) || 0;
            const asset = assets?.find((asset) => isCoinsEquals(asset, toHubCoins(coins), true));
            return { coins, transfer, price, network, asset, showNetworkDomain, showBaseDenom };
        });
        if (sortByValue) {
            list.sort((balance1, balance2) => balance2.price - balance1.price);
        }
        return list;
    }, [ assets, balances, fetchCoinsAndTransfer, getNetwork, getTokenPrice, sortByValue, toHubCoins ]);

    const emptyLabel = useMemo(() => balancesInfo.some((balance) => balance.transfer) ? 'No Transfers' : 'No Balances', [ balancesInfo ]);

    const getNetworkDomain = useCallback((network: Network) => {
        const alias = dymnsState.aliasesMap[network.chainId]?.aliases?.[0];
        return alias ? `@${alias}` : network.chainName;
    }, [ dymnsState.aliasesMap ]);

    return (
        <ul className={classNames('account-balances', className)}>
            {header}
            {loading ? <Spinner className='balances-loader' /> : !balancesInfo.length && <span className='no-balances'>{emptyLabel}</span>}
            {balancesInfo.map(({ asset, coins, transfer, price, showNetworkDomain, showBaseDenom, network }, balanceIndex) => (
                <li
                    className={classNames('balance-row', { canClick: Boolean(asset) })}
                    key={balanceIndex}
                    onClick={() => asset && onAssetClick(asset, transfer)}
                >
                    <img className='currency-logo' src={getCurrencyLogoPath(coins.currency, network)} alt='currency logo' />

                    <span
                        className={classNames(
                            'currency-name-container', { long: (showNetworkDomain || showBaseDenom) && transfer?.time },
                        )}
                    >
                        <DisplayNameWithFuture coins={coins} />
                        {(transfer?.time || showNetworkDomain || showBaseDenom) ? (
                            <div className='chain-name-container horizontally-centered'>
                                {showNetworkDomain ?
                                    <span className='currency-network nowrap'>{getNetworkDomain(network)}</span> :
                                    undefined}
                                {showBaseDenom ? (
                                    <span className='currency-network nowrap'>
                                        {!CUSTOM_BASE_DENOM_PREFIX_REGEX.test(coins.currency.baseDenom) ? coins.currency.baseDenom :
                                            getShortenedAddress(coins.currency.baseDenom.replace(CUSTOM_BASE_DENOM_PREFIX_REGEX, ''))}
                                    </span>
                                ) : undefined}
                                {transfer?.time && (showNetworkDomain || showBaseDenom) ?
                                    <span className='secondary-text'>&nbsp;•&nbsp;</span> : ''}
                                {transfer?.time ?
                                    <span className='transfer-time nowrap'>{getShortDateTimeString(transfer.time)}</span> : undefined}
                            </div>
                        ) : undefined}
                    </span>
                    {transfer && (
                        <Badge
                            label={transfer.claimable ? 'Claimable' : 'Pending'}
                            size='small'
                            className='finalized-badge'
                            color={getCssVariableValue(
                                transfer.claimable ? '--light-green-rgb' : '--orange-rgb').split(',').map(Number)}
                        />
                    )}

                    {coins.amount !== undefined && (
                        <span className='currency-option-balance'>
                            {formatPrice(coins.amount, '', undefined, 10)}
                            <span className='balance-value'>{formatPrice(price)}</span>
                        </span>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default BalancesList;
