import classNames from 'classnames';
import React from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { AccountNetworkState } from '../../../account-network-state';
import './account-nfts-amount.scss';

interface AccountNftsAmountProps {
    className?: string;
    networkState: AccountNetworkState;
}

const AccountNftsAmount: React.FC<AccountNftsAmountProps> = ({ className, networkState }) => {
    return (
        <Property
            label='Total Amount'
            className={classNames('account-nfts-amount', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {networkState.nfts === undefined && networkState.nftsLoading ? <Spinner /> : formatNumber(networkState.nfts?.length || 0)}
        </Property>
    );
};

export default AccountNftsAmount;
