import React, { useCallback, useEffect, useMemo } from 'react';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { useHubNetworkState } from '../../../../../../account/hub-network-state-context';
import { isDenomsEquals } from '../../../../../../currency/currency-service';
import { CoinsAmount } from '../../../../../../currency/currency-types';
import { useIRO } from '../../../../../../iro/iro-context';
import { useNetwork } from '../../../../../../network/network-context';
import AmountTx from '../../../../../../tx/amount-tx/amount-tx';
import { useAmountTx } from '../../../../../../tx/amount-tx/use-amount-tx';
import { useCreateRollapp } from '../../../create-rollapp-context';
import './iro-initial-purchase-section.scss';

const IroInitialPurchaseSection: React.FC = () => {
    const { hubCurrency } = useNetwork();
    const networkState = useHubNetworkState();
    const { iroParams } = useIRO();
    const { showErrors, iro, haveIRO, rollapp, initialPurchaseEnabled, iroPlanTxState, setIro } = useCreateRollapp();

    const disabled = useMemo(
        () => Boolean(!iroParams || rollapp.sealed || !haveIRO || !initialPurchaseEnabled),
        [ haveIRO, initialPurchaseEnabled, iroParams, rollapp.sealed ],
    );

    const availableBalances = useMemo((): CoinsAmount[] => {
        const balance = hubCurrency && networkState.balances?.find((balance) => isDenomsEquals(balance, hubCurrency?.baseDenom));
        return !balance ? [] : [ { ...balance, amount: Math.min(balance.amount, iro.targetRaise || 0) } ];
    }, [ hubCurrency, iro.targetRaise, networkState.balances ]);

    const extraFees = useMemo((): CoinsAmount[] => iroPlanTxState.fee ? [ iroPlanTxState.fee.coins ] : [], [ iroPlanTxState.fee ]);

    const { amountTxState, setCoins } = useAmountTx({ networkState, availableBalances, extraFees });

    const onInitialPurchaseChange = useCallback((coins: CoinsAmount) => {
        setCoins(coins);
        setIro({ ...iro, initialPurchase: coins });
    }, [ iro, setCoins, setIro ]);

    useEffect(() => {
        if (iro.initialPurchase) {
            setCoins(iro.initialPurchase);
        }
    }, [ iro.initialPurchase, setCoins ]);

    return (
        <div className='iro-initial-purchase-section section'>
            <h5 className='section-header'>
                Initial Purchase
                <InfoIndicator indicatorSize='small'>
                    To avoid front running it’s recommended you acquire the first tokens as part of the setup.
                </InfoIndicator>
            </h5>
            <AmountTx
                txState={{}}
                amountTxState={amountTxState}
                networkState={networkState}
                onCoinsChange={onInitialPurchaseChange}
                availableBalances={availableBalances}
                displayFee={false}
                tokenSelectorDisabled={disabled}
                amountDisabled={disabled}
                error={showErrors && iro.initialPurchase === undefined ?
                    'Initial purchase amount is required (must be 0 or higher).' : undefined}
            />
        </div>
    );
};

export default IroInitialPurchaseSection;
