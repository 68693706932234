import React, { ReactElement, useCallback } from 'react';
import Addresses from '../../../shared/components/addresses/addresses';
import Badge from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { formatPrice, roundNumber } from '../../../shared/utils/number-utils';
import { useNetwork } from '../../network/network-context';
import { INCENTIVES_HUB_ADDRESS, IRO_HUB_ADDRESS } from '../../rollapp/manage-rollapps-page/create-rollapp-page/types';
import { convertToBech32Address } from '../../wallet/wallet-service';
import { useAsset } from '../asset-context';
import { Asset } from '../asset-types';
import { Holder } from './holder-types';
import { useHolderList } from './use-holder-list';
import './holder-list.scss';

interface HolderListProps {
    asset: Asset;
}

const HolderList: React.FC<HolderListProps> = ({ asset }) => {
    const { hubNetwork } = useNetwork();
    const { getTokenPrice } = useAsset();
    const { holders, loading } = useHolderList(asset, true);

    const getAddressLabel = useCallback((address: string) => {
        if (!address) {
            return 'On RollApp';
        } else if (address === asset.network.owner) {
            return 'Founder';
        } else if (address === IRO_HUB_ADDRESS) {
            return 'Curve';
        } else if (address === INCENTIVES_HUB_ADDRESS) {
            return 'LP incentives';
        }
    }, [ asset.network.owner ]);

    const renderAddressColumn = (holder: Holder): ReactElement => {
        const address = holder.hexAddress && convertToBech32Address(holder.hexAddress, hubNetwork?.bech32Prefix || '');
        const label = getAddressLabel(address);
        return (
            <TableColumn>
                {address && <><Addresses addresses={[ holder.hexAddress, address ]} canCopy />&nbsp;&nbsp;</>}
                {label && (
                    <Badge
                        label={label}
                        size='small'
                        rounded
                        className='address-badge'
                        color={getCssVariableValue('--light-blue-rgb').split(',').map(Number)}
                    />
                )}
            </TableColumn>
        );
    };

    const renderAmountColumn = (holder: Holder): ReactElement => {
        return <TableColumn>{formatPrice(holder.amount, '')}</TableColumn>;
    };

    const renderValueColumn = (holder: Holder): ReactElement => {
        return (
            <TableColumn>
                {formatPrice(getTokenPrice({ ...asset, amount: holder.amount }) || 0, undefined, { notation: 'compact' })}
            </TableColumn>
        );
    };

    const renderWeightColumn = (holder: Holder): ReactElement => {
        return <TableColumn>{holder.weight < 0.0001 ? '< 0.01' : roundNumber(holder.weight * 100, 2)}%</TableColumn>;
    };

    const renderHolderHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Address</TableColumn>
                <TableColumn>Amount ({asset.currency.displayDenom})</TableColumn>
                <TableColumn>Value (USD)</TableColumn>
                <TableColumn info={asset.iroDenom ? `From the available IRO allocation` : undefined}>Weight</TableColumn>
            </TableRow>
        );
    };

    const renderHolderRow = (holder: Holder): ReactElement => {
        return (
            <TableRow key={holder.hexAddress} className='holder-row'>
                {renderAddressColumn(holder)}
                {renderAmountColumn(holder)}
                {renderValueColumn(holder)}
                {renderWeightColumn(holder)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!holders?.length) {
            return <div className='no-data'>No Holders Yet</div>;
        }
    };

    return (
        <div className='holder-list-container'>
            <Table className='holder-list' indexColumn headerSticky bottomBar={renderBottomBar()}>
                {renderHolderHeaderRow()}
                {holders?.map(renderHolderRow)}
            </Table>
        </div>
    );
};

export default HolderList;
