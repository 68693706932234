import { Connection, PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import Web3 from 'web3';
import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { POOL_SHARE_PREFIX } from '../amm/amm.service';
import { ALL_ITEMS_PAGINATION } from '../client/client-types';
import { Network } from '../network/network-types';
import { convertToHexAddress } from '../wallet/wallet-service';
import { CoinsAmount, NetworkDenom, NFTToken } from '../currency/currency-types';
import { convertToCoinsAmount, createEmptyCoinsAmount, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { StationClient } from '../client/station-clients/station-client';
import { readStream } from '../../shared/utils/file-utils';

export const getBalances = async (client: StationClient, address: string): Promise<{ balances: CoinsAmount[], fetchError?: any }> => {
    const network = client.getNetwork();
    const { balances } = await client.getBankQueryClient().AllBalances({ address, pagination: ALL_ITEMS_PAGINATION }).catch((error) => {
        return { balances: [], fetchError: error };
    });

    const fixedBalances = await Promise.all(balances.filter((balance) => !balance.denom.startsWith(POOL_SHARE_PREFIX))
        .map((coin) => convertToCoinsAmount(coin, client)));

    let fetchError;
    if (network.evm) {
        await getERC20Balances(client, address)
            .then((erc20Balances) => fixedBalances.push(...erc20Balances.filter((balance) => balance.amount)))
            .catch((error) => fetchError = error);
    }

    const otherNetworkBalances: CoinsAmount[] = network.currencies
        .filter((currency) => currency &&
            fixedBalances.every((coins) => !coins ||
                !isCoinsEquals(coins, createEmptyCoinsAmount(network, currency, client.getHubNetworkDenoms()))))
        .map((currency) => createEmptyCoinsAmount(network, currency, client.getHubNetworkDenoms()));
    fixedBalances.push(...otherNetworkBalances);

    return {
        balances: filterNonEmptyValues(fixedBalances).sort((balance1, balance2) =>
            ((balance2?.amount ? 1 : 0) - (balance1?.amount ? 1 : 0)) || ((balance1?.ibc ? 1 : 0) - (balance2?.ibc ? 1 : 0))),
        fetchError,
    };
};

export const getERC20Tokens = async (client: StationClient): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    if (!network.rest || network.type === 'Hub') {
        return [];
    }
    const tokenPairResponse = await fetch(`${network.rest}/evmos/erc20/v1/token_pairs`, { mode: 'no-cors' });
    if (!tokenPairResponse?.ok) {
        throw new Error(`Can't fetch token pairs`);
    }
    const tokenPairResponseText = tokenPairResponse?.body ? await readStream(tokenPairResponse.body) : undefined;
    const tokenPairs = ((JSON.parse(tokenPairResponseText || '{}')?.token_pairs || []) as { erc20_address: string, denom: string }[])
        .map(({ erc20_address, denom }) => ({ erc20Address: erc20_address, denom }));

    const tokens = await Promise.all(tokenPairs.map(async (tokenPair) => {
        const coins = await convertToCoinsAmount({ amount: '0', denom: tokenPair.denom }, client);
        return coins ? { ...coins, erc20Address: tokenPair.erc20Address } : coins;
    }));
    return tokens.filter(Boolean) as CoinsAmount[];
};

export const getERC20Balances = async (client: StationClient, address: string): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    if (!network.evm?.rpc) {
        return [];
    }
    const tokens = await getERC20Tokens(client);
    if (!tokens.length) {
        return [];
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    const walletAddress = convertToHexAddress(address);
    return Promise.all(tokens.map(async (coins) => {
        const contract = new Web3Client.eth.Contract(balanceOfABI as any, coins.erc20Address);
        const balance = await contract.methods.balanceOf(walletAddress).call();
        return { ...coins, amount: getMaxDenomAmount(Number(balance), coins.currency), baseAmount: BigInt(balance) };
    }));
};

export const getEvmBalances = async (
    network: Network,
    hexAddress: string,
    networkDenoms?: NetworkDenom[],
): Promise<CoinsAmount[]> => {
    if (!network.evm?.rpc) {
        return [];
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    return Promise.all(network.currencies.map(async (currency) => {
        let balance;
        if (currency.type === 'main') {
            balance = Number(await Web3Client.eth.getBalance(hexAddress));
        } else {
            const contract = new Web3Client.eth.Contract(balanceOfABI as any, currency.bridgeDenom || '');
            balance = Number(await contract.methods.balanceOf(hexAddress).call());
        }
        let ibc: CoinsAmount['ibc'] | undefined = undefined;
        let networkId = currency.type === 'main' ? network.chainId : '';
        if (currency.ibcRepresentation) {
            const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
            networkId = networkDenom?.ibcNetworkId || networkId;
            ibc = { representation: currency.ibcRepresentation, path: networkDenom?.path || '' };
        }
        return { currency, amount: getMaxDenomAmount(balance, currency), ibc, networkId };
    }));
};

export const getSolanaBalances = async (
    network: Network,
    address: string,
    networkDenoms?: NetworkDenom[],
): Promise<CoinsAmount[]> => {
    if (!network.rpc) {
        return [];
    }
    const connection = new Connection(network.rpc);
    const payerKey = new PublicKey(address);
    return Promise.all(network.currencies
        .filter((currency) => currency.solanaMintAccount && currency.ibcRepresentation)
        .map(async (currency) => {
            const ata = await getAssociatedTokenAddress(new PublicKey(currency.solanaMintAccount || ''), payerKey);
            const accountData = await getAccount(connection, ata, 'confirmed');
            const amount = Number(accountData.amount) || 0;
            const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
            const ibc: CoinsAmount['ibc'] = {
                representation: currency.ibcRepresentation || '',
                path: networkDenom?.path || '',
            };
            return { currency, amount: getMaxDenomAmount(amount, currency), ibc, networkId: networkDenom?.ibcNetworkId || network.chainId };
        }));
};

export const getNFTs = async (client: StationClient, address: string): Promise<NFTToken[]> => {
    const network = client.getNetwork();
    if (!network.evm?.rpc) {
        return [];
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const erc721Abi = [
        {
            constant: true,
            inputs: [ { name: 'owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: 'from', type: 'address' },
                { indexed: true, name: 'to', type: 'address' },
                { indexed: true, name: 'tokenId', type: 'uint256' },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            constant: true,
            inputs: [ { name: 'owner', type: 'address' }, { name: 'index', type: 'uint256' } ],
            name: 'tokenOfOwnerByIndex',
            outputs: [ { name: 'tokenId', type: 'uint256' } ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [ { name: 'tokenId', type: 'uint256' } ],
            name: 'tokenURI',
            outputs: [ { name: 'uri', type: 'string' } ],
            type: 'function',
        },
    ];
    const walletAddress = convertToHexAddress(address);
    const contract = new Web3Client.eth.Contract(erc721Abi as any, '0xd2CBbC6D8f0903945467d1F1c9EedFC7144F63f8');
    const currentBlock = await Web3Client.eth.getBlockNumber();

    const tokenIds = [];
    for (let fromBlock = 20000; fromBlock <= currentBlock; fromBlock += 10000) {
        const toBlock = Math.min(fromBlock + 10000 - 1, currentBlock);
        const events = await contract.getPastEvents('Transfer', { fromBlock, toBlock, filter: { to: walletAddress } });
        tokenIds.push(...events.map((event) => event.returnValues.tokenId));
    }
    return Promise.all(tokenIds.map(async (tokenId) => {
        const tokenURI = await contract.methods.tokenURI(tokenId).call();
        let metadata = { image: tokenURI, name: `Token ${tokenId}` };
        if (tokenURI.startsWith('http')) {
            const response = await fetch(tokenURI);
            metadata = await response.json();
        } else if (tokenURI.startsWith('ipfs://')) {
            const response = await fetch(tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/'));
            metadata = await response.json();
            metadata.image = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
        }
        return { tokenId, ...metadata };
    }));
};

