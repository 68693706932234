import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { uniqBy } from 'lodash';
import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { roundNumber } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useAsset } from '../../asset/asset-context';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import {
    getFixedDenom,
    getMinDenomAmount,
    isCoinsEquals,
} from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { DEFAULT_SLIPPAGE_TOLERANCE } from '../../trade/trade-context';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { getBaseAmountWithoutFee } from '../../tx/tx-service';
import { TxState } from '../../tx/tx-state';
import { DeliveryTxCode, Fee, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useAmm } from '../amm-context';
import { createSwapMessage, getConnectedPools } from '../amm.service';
import { estimateSwapAmountIn, estimateSwapAmountOut } from '../pools-service';

interface TokensSwapContextValue {
    asset1AmountTxState: AmountTxState;
    asset2AmountTxState: AmountTxState;
    updateAsset1Coins: (coins: CoinsAmount) => void;
    updateAsset2Coins: (coins: CoinsAmount) => void;
    availableBalances: CoinsAmount[];
    txState: TxState;
    fees: Fee[];
    loading: boolean;
    spotPrice: number;
    slippageTolerance?: number;
    currentSlippage: number;
    setSlippageTolerance: (value?: number) => void;
    useInverseAsset: boolean;
    setUseInverseAsset: (value: boolean) => void;
    switchTokens: () => void;
    broadcast: (memo?: string) => void;
    getTokensMinAmounts: (coins1: CoinsAmount, coins2: CoinsAmount) => { minCoins1Amount: number, tokenOutMinAmount: string };
    getTxResponseMessage: (response: TxResponse) => Partial<SnackbarMessage> | undefined;
}

const SLIPPAGE_TOLERANCE_KEY = 'slippageToleranceKey';

export const TokensSwapContext = createContext<TokensSwapContextValue>({} as TokensSwapContextValue);

export const TokensSwapContextProvider = ({
    children,
    initialAsset1,
    initialAsset2,
}: { children: ReactNode, initialAsset1?: CoinsAmount, initialAsset2?: CoinsAmount }) => {
    const { hubNetwork } = useNetwork();
    const { ammState, getTokenPrice } = useAmm();
    const { assets } = useAsset();
    const networkState = useHubNetworkState();
    const { handleClientError } = useClient();
    const { hubWallet, handleWalletError } = useWallet();
    const [ slippageTolerance, setSlippageTolerance ] = usePersistedState<number | undefined>(SLIPPAGE_TOLERANCE_KEY, undefined);
    const [ currentSlippage, setCurrentSlippage ] = useState(0);
    const [ useInverseAsset, setUseInverseAsset ] = useState(false);
    const [ asset1Fees, setAsset1Fees ] = useState<CoinsAmount[]>([]);

    const availableBalances = useMemo(() => {
        if (!ammState.pools) {
            return [];
        }
        const allAssets = ammState.pools.reduce((assets, pool) => [ ...assets, ...pool.assets ], [] as CoinsAmount[]);
        return uniqBy(allAssets, (asset) => getFixedDenom(asset))
            .map((asset) => {
                const balance = networkState.balances?.find((balance) => isCoinsEquals(asset, balance));
                return { ...asset, amount: balance?.amount || 0, baseAmount: balance?.baseAmount };
            })
            .sort((asset1, asset2) => Math.sign(asset2.amount) - Math.sign(asset1.amount) ||
                asset1.currency.displayDenom.localeCompare(asset2.currency.displayDenom));
    }, [ ammState.pools, networkState.balances ]);

    const {
        amountTxState: asset1AmountTxState,
        setCoins: setAsset1Coins,
        setAmount: setAsset1Amount,
    } = useAmountTx({ networkState, availableBalances, extraFees: asset1Fees });

    const getTokensMinAmounts = useCallback((
        coins1: CoinsAmount,
        coins2: CoinsAmount,
    ): { minCoins1Amount: number, tokenOutMinAmount: string } => {
        const coins1Decimals = coins1.currency.decimals;
        const coins2Decimals = coins2.currency.decimals;
        const minCoins1Amount = Math.pow(10, 1 + Math.max(1, coins1Decimals - coins2Decimals));
        const minCoins2Amount = Math.pow(10, Math.max(0, coins2Decimals - coins1Decimals));
        const tokenOutMinAmount = BigInt(Math.max(minCoins2Amount, getMinDenomAmount(
            coins2.amount * (1 - ((slippageTolerance || DEFAULT_SLIPPAGE_TOLERANCE) / 100)), coins2.currency))).toString();
        return { minCoins1Amount, tokenOutMinAmount };
    }, [ slippageTolerance ]);

    const loading = useMemo(
        () => Boolean((!assets && ammState.loading) || (!ammState.params && ammState.paramsLoading)),
        [ assets, ammState.loading, ammState.params, ammState.paramsLoading ],
    );

    const createSwapMessagesCreator = useCallback((fee?: CoinsAmount, coins2?: CoinsAmount): EncodeObject[] => {
        if (!ammState.pools || !ammState.params || !asset1AmountTxState.coins || !coins2 || !networkState.address) {
            return [];
        }
        const connectedPools = getConnectedPools(ammState.pools, ammState.params, asset1AmountTxState.coins, coins2);
        if (!connectedPools.length) {
            return [];
        }
        const { minCoins1Amount, tokenOutMinAmount } = getTokensMinAmounts(asset1AmountTxState.coins, coins2);
        const coins = { ...asset1AmountTxState.coins, amount: asset1AmountTxState.coins.amount || minCoins1Amount };
        const baseAmountWithoutFee = getBaseAmountWithoutFee(coins, networkState.balances, fee);
        const message = createSwapMessage(networkState.address, coins, connectedPools, tokenOutMinAmount, baseAmountWithoutFee);
        return [ message ];
    }, [ ammState.params, ammState.pools, asset1AmountTxState.coins, getTokensMinAmounts, networkState.address, networkState.balances ]);

    const {
        txState,
        amountTxState: asset2AmountTxState,
        setCoins: setAsset2Coins,
        setAmount: setAsset2Amount,
        broadcast,
        calculateFee,
        clearFee,
    } = useAmountTx({ networkState, selectInitialCurrency: false, availableBalances, amountTxMessagesCreator: createSwapMessagesCreator });

    useEffect(() => setAsset1Fees(txState.fee ? [ txState.fee.coins ] : []), [ txState.fee ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (hubWallet && networkState.network && asset1AmountTxState.coins?.currency && asset2AmountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [
        asset1AmountTxState.coins?.currency,
        asset2AmountTxState.coins?.currency,
        calculateFee,
        clearFee,
        hubWallet,
        networkState.network,
    ]);

    useEffect(() => {
        if (txState.response) {
            setAsset1Amount(0);
        }
    }, [ setAsset1Amount, txState.response ]);

    const updateAsset1Coins = useCallback((coins: CoinsAmount) => {
        setAsset1Coins(coins);
        if (!asset2AmountTxState.coins || !coins.amount || !ammState.pools || !ammState.params) {
            return;
        }
        const amount = estimateSwapAmountIn(ammState.pools, ammState.params, coins, asset2AmountTxState.coins);
        setAsset2Amount(amount);
    }, [ ammState.params, ammState.pools, asset2AmountTxState.coins, setAsset1Coins, setAsset2Amount ]);

    const updateAsset2Coins = useCallback((coins: CoinsAmount) => {
        setAsset2Coins(coins);
        if (!asset1AmountTxState.coins || !coins.amount || !ammState.pools || !ammState.params) {
            return;
        }
        let { amountIn, isAmountOutFixed } = estimateSwapAmountOut(ammState.pools, ammState.params, asset1AmountTxState.coins, coins);
        if (amountIn > asset1AmountTxState.availableAmount) {
            amountIn = asset1AmountTxState.availableAmount;
            isAmountOutFixed = true;
        }
        if (isAmountOutFixed) {
            const amountOut =
                estimateSwapAmountIn(ammState.pools, ammState.params, { ...asset1AmountTxState.coins, amount: amountIn }, coins);
            setAsset2Coins({ ...coins, amount: amountOut });
        }
        setAsset1Amount(amountIn);
    }, [
        ammState.params,
        ammState.pools,
        asset1AmountTxState.availableAmount,
        asset1AmountTxState.coins,
        setAsset1Amount,
        setAsset2Coins,
    ]);

    const switchTokens = useCallback(() => {
        if (asset1AmountTxState.coins && asset2AmountTxState.coins) {
            setAsset1Coins({ ...asset2AmountTxState.coins, amount: 0 });
            setAsset2Coins({ ...asset1AmountTxState.coins, amount: 0 });
        }
    }, [ asset1AmountTxState.coins, asset2AmountTxState.coins, setAsset1Coins, setAsset2Coins ]);

    useEffect(() => {
        if (!asset1AmountTxState.coins?.amount || !asset2AmountTxState.coins?.amount) {
            setTimeout(() => setCurrentSlippage(0), 50);
            return;
        }
        const oldPrice = getTokenPrice(asset1AmountTxState.coins, asset2AmountTxState.coins, true);
        if (oldPrice) {
            const newPrice = (asset2AmountTxState.coins.amount) / (1 - (ammState.params?.takerFee || 0)) / asset1AmountTxState.coins.amount;
            setTimeout(() => setCurrentSlippage(Math.abs(100 * (oldPrice - newPrice) / oldPrice)), 50);
        }
    }, [
        ammState.params?.swapFee,
        ammState.params?.takerFee,
        asset1AmountTxState.coins,
        asset2AmountTxState.coins,
        getTokenPrice,
    ]);

    useEffect(() => {
        if (asset1AmountTxState.coins && !asset2AmountTxState.coins) {
            const coins =
                availableBalances.find((balance) => asset1AmountTxState.coins && !isCoinsEquals(balance, asset1AmountTxState.coins));
            if (coins) {
                setAsset2Coins({ ...coins, amount: 0 });
            }
        }
    }, [ asset1AmountTxState.coins, asset2AmountTxState.coins, availableBalances, setAsset2Coins ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        const coins1 = initialAsset1 && availableBalances.find((balance) => isCoinsEquals(balance, initialAsset1));
        if (coins1 && !asset1AmountTxState.coins) {
            setAsset1Coins({ ...coins1, amount: 0 });
        }
        const coins2 = initialAsset2 && availableBalances.find((balance) => isCoinsEquals(balance, initialAsset2));
        if (coins2 && !asset2AmountTxState.coins) {
            setAsset2Coins({ ...coins2, amount: 0 });
        }
    }, [
        asset1AmountTxState.coins,
        asset2AmountTxState.coins,
        availableBalances,
        hubNetwork,
        initialAsset1,
        initialAsset2,
        setAsset1Coins,
        setAsset2Coins,
    ]);

    const fees = useMemo((): Fee[] => [
        {
            label: 'Swap fee',
            value: `${roundNumber((ammState.params?.swapFee || 0) * 100, 2)}%`,
            loading: !ammState.params && ammState.paramsLoading,
        },
        {
            label: 'Protocol burn fee',
            value: `${roundNumber((ammState.params?.takerFee || 0) * 100, 2)}%`,
            loading: !ammState.params && ammState.paramsLoading,
        },
    ], [ ammState.params, ammState.paramsLoading ]);

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: 'Tokens swap successfully completed!' };
        }
    }, []);

    const spotPrice = useMemo(
        () => !asset1AmountTxState.coins ? 0 :
            getTokenPrice(asset1AmountTxState.coins, asset2AmountTxState.coins, true) || 0,
        [ asset1AmountTxState.coins, asset2AmountTxState?.coins, getTokenPrice ],
    );

    return (
        <TokensSwapContext.Provider
            value={{
                asset1AmountTxState,
                asset2AmountTxState,
                txState,
                fees,
                loading,
                currentSlippage,
                updateAsset1Coins,
                updateAsset2Coins,
                availableBalances,
                switchTokens,
                slippageTolerance,
                getTokensMinAmounts,
                getTxResponseMessage,
                spotPrice,
                useInverseAsset,
                setUseInverseAsset,
                setSlippageTolerance,
                broadcast,
            }}
        >
            {children}
        </TokensSwapContext.Provider>
    );
};


