import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogAction, DialogContent, DialogFooter, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Input from '../../../../shared/components/form-controls/input/input';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../../shared/components/form-controls/select/select';
import { roundNumber } from '../../../../shared/utils/number-utils';
import { getShortenedAddress } from '../../../../shared/utils/text-utils';
import { AccountNetworkState } from '../../../account/account-network-state';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useAccountNetwork } from '../../../account/use-account-network';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import { getFixedDenom, getMainCurrency, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import NetworkSelector from '../../../network/network-selector/network-selector';
import { Network } from '../../../network/network-types';
import AmountTx from '../../../tx/amount-tx/amount-tx';
import { useAmountTx } from '../../../tx/amount-tx/use-amount-tx';
import TransactionFee from '../../../tx/transaction-fee/transaction-fee';
import useHandleTxResponses from '../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../wallet/wallet-context';
import { useEibcClient } from '../eibc-client-context';
import { RollappLiquidity } from '../eibc-client-types';
import { useRollappLiquidity } from './use-rollapp-liquidity';
import './rollapp-liquidity-dialog.scss';

interface RollappLiquidityTokenProps {
    networkData: AccountNetworkState;
    coins: CoinsAmount;
    availableBalances: CoinsAmount[];
    onRemove: () => void;
    error?: string;
    onCoinsChange: (coins: CoinsAmount) => void;
}

const RollappLiquidityToken: React.FC<RollappLiquidityTokenProps> = ({
    networkData,
    coins,
    availableBalances,
    onRemove,
    onCoinsChange,
    error,
}) => {
    const { amountTxState, setCoins } = useAmountTx({ networkState: networkData, availableBalances, selectInitialCurrency: false });

    useEffect(() => setCoins(coins), [ coins, setCoins ]);

    return (
        <div className='rollapp-liquidity-token-container'>
            <AmountTx
                displayFee={false}
                txState={{}}
                amountTxState={amountTxState}
                networkState={networkData}
                error={error}
                onCoinsChange={(coins) => {
                    setCoins(coins);
                    onCoinsChange(coins);
                }}
                availableBalances={availableBalances}
            />
            <Button buttonType='icon' onClick={onRemove}><ClearIcon /></Button>
        </div>
    );
};

interface RollappLiquidityDialogProps extends DialogProps {
    editedLiquidity?: RollappLiquidity;
}

const RollappLiquidityDialog: React.FC<RollappLiquidityDialogProps> = ({ editedLiquidity, ...otherDialogProps }) => {
    const { hubNetwork, hubCurrency, rollapps, getNetwork } = useNetwork();
    const { hubWallet } = useWallet();
    const { groups, groupsLoading } = useEibcClient();
    const { updateLiquidity, save, txState, showErrors, liquidity } = useRollappLiquidity(editedLiquidity);
    const [ rollappNetworkData, setRollapp ] = useAccountNetwork(liquidity.rollapp, false, false);
    const networkData = useHubNetworkState();
    useHandleTxResponses(txState, hubWallet, otherDialogProps.onRequestClose);

    const rollappGroups = useMemo(() => liquidity.rollapp &&
        groups?.filter((group) => group.metadata.supported_rollapps.includes(liquidity.rollapp!.chainId)), [ groups, liquidity.rollapp ]);

    const optionalNetworks = useMemo(() => {
        return rollapps.filter((rollapp) => rollapp.chainId === liquidity.rollapp?.chainId ||
            ((rollapp.status === 'Active' || rollapp.status === 'Degraded') &&
                groups?.some((group) => group.metadata.supported_rollapps.includes(rollapp.chainId))));
    }, [ groups, liquidity.rollapp?.chainId, rollapps ]);

    const getDefaultTokens = useCallback((rollapp: Network): CoinsAmount[] => {
        if (!hubNetwork || !hubCurrency || !networkData.balances) {
            return [];
        }
        const rollappCurrency = getMainCurrency(rollapp);
        return networkData.balances
            .filter((balance) =>
                isCoinsEquals(balance, { amount: 0, currency: rollappCurrency, networkId: rollapp.chainId }) ||
                isCoinsEquals(balance, { amount: 0, currency: hubCurrency, networkId: hubNetwork?.chainId }))
            .map((balance) => ({ ...balance, amount: 0 }));
    }, [ hubCurrency, hubNetwork, networkData.balances ]);

    useEffect(() => {
        if (rollappNetworkData.network && rollappNetworkData.network.chainId !== liquidity.rollapp?.chainId) {
            updateLiquidity({ rollapp: rollappNetworkData.network, tokens: getDefaultTokens(rollappNetworkData.network) });
        }
    }, [ getDefaultTokens, liquidity.rollapp, rollappNetworkData.network, updateLiquidity ]);

    const onPercentageValueChange = useCallback((
        field: keyof RollappLiquidity,
        value: string,
        previousValue: string,
    ): string | undefined => {
        if (!value) {
            updateLiquidity({ [field]: undefined });
            return '';
        }
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const amountPattern = new RegExp('^([0-9]{1,3})(\\.[0-9]{0,4})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        const numberValue = Number(value);
        if (numberValue) {
            updateLiquidity({ [field]: Math.min(1, Math.max(0, roundNumber(numberValue / 100, 6))) });
        }
        return numberValue < 0 ? '0' : numberValue > 100 ? '100' : value;
    }, [ updateLiquidity ]);

    const getAvailableTokens = useCallback((token?: CoinsAmount) => {
        return (networkData.balances || []).filter((balance) => ((token && isCoinsEquals(token, balance)) ||
            liquidity.tokens?.every((otherToken) => !otherToken || !isCoinsEquals(balance, otherToken))));
    }, [ liquidity.tokens, networkData.balances ]);

    const removeToken = useCallback((tokenIndex: number) => {
        if (!liquidity.tokens || liquidity.tokens.length <= 1) {
            return;
        }
        liquidity.tokens.splice(tokenIndex, 1);
        updateLiquidity({ tokens: liquidity.tokens });
    }, [ liquidity.tokens, updateLiquidity ]);

    const updateToken = useCallback((tokenIndex: number, token: CoinsAmount) => {
        if (liquidity.tokens) {
            liquidity.tokens[tokenIndex] = token;
            updateLiquidity({ tokens: liquidity.tokens });
        }
    }, [ liquidity.tokens, updateLiquidity ]);

    const addToken = useCallback(() => {
        if (!liquidity.tokens) {
            return;
        }
        const availableTokens = getAvailableTokens();
        if (liquidity.rollapp && availableTokens.length) {
            const defaultTokens = getDefaultTokens(liquidity.rollapp);
            const token = defaultTokens.find((token) => availableTokens.some((otherToken) => isCoinsEquals(token, otherToken)));
            liquidity.tokens.push(token || { ...availableTokens[0], amount: 0 });
            updateLiquidity({ tokens: liquidity.tokens });
        }
    }, [ getAvailableTokens, getDefaultTokens, liquidity.rollapp, liquidity.tokens, updateLiquidity ]);

    return (
        <Dialog className='rollapp-liquidity-dialog' closable {...otherDialogProps}>
            <DialogTitle>Provide RollApp Liquidity</DialogTitle>
            <DialogContent className='liquidity-dialog-content'>
                <label className='liquidity-control-label'>RollApp</label>
                <NetworkSelector
                    networks={optionalNetworks}
                    hideWalletSelector
                    networkData={rollappNetworkData}
                    size='small'
                    onNetworkSelect={(chainId) => setRollapp(getNetwork(chainId))}
                />

                <label className='liquidity-control-label'>Operator Group Address</label>
                <Select
                    disabled={!rollappGroups?.length}
                    placeholder='Choose operator group...'
                    searchPlaceholder='Search by group address...'
                    emptySearchResultsLabel='No groups'
                    loading={groupsLoading}
                    onSelect={(value) => updateLiquidity({ operatorGroupAddress: value.toString() })}
                    value={liquidity?.operatorGroupAddress}
                    searchFilterPredicate={(query, value) => value.toString().includes(query)}
                    error={showErrors && !liquidity?.operatorGroupAddress && 'Missing operator group address'}
                    className='operator-groups-selector'
                >
                    {rollappGroups?.map((group) => (
                        <Option value={group.metadata.policy_address} key={group.metadata.policy_address}>
                            <b>{group.metadata.moniker}</b>&nbsp;
                            <span className='secondary-text'>
                                ({getShortenedAddress(group.metadata.policy_address, 12, 10)})
                            </span>
                        </Option>
                    ))}
                </Select>

                <div className='horizontally-centered'>
                    <div className='liquidity-control-container'>
                        <label className='liquidity-control-label'>Minimum LP Fee</label>
                        <Input
                            placeholder='e.g., 0.1'
                            suffix='%'
                            value={liquidity?.minLpFee ? roundNumber(liquidity.minLpFee * 100, 4) : undefined}
                            onValueChange={(value, previousValue) => onPercentageValueChange('minLpFee', value, previousValue)}
                            error={showErrors && !liquidity?.minLpFee ? 'Missing minimum LP fee' : undefined}
                        />
                    </div>

                    <div className='liquidity-control-container'>
                        <label className='liquidity-control-label'>Operator Fee Share</label>
                        <Input
                            placeholder='e.g., 0.1'
                            suffix='%'
                            value={liquidity?.operatorFeeShare ? roundNumber(liquidity.operatorFeeShare * 100, 4) : undefined}
                            onValueChange={(value, previousValue) => onPercentageValueChange('operatorFeeShare', value, previousValue)}
                            error={showErrors && !liquidity?.operatorFeeShare ? 'Missing operator fee share' : undefined}
                        />
                    </div>
                </div>

                <label className='liquidity-control-label'>Spend Limit</label>
                <div className='section small tokens-section'>
                    {liquidity.tokens?.map((token, tokenIndex) =>
                        <RollappLiquidityToken
                            key={getFixedDenom(token)}
                            networkData={networkData}
                            coins={token}
                            error={showErrors && !token.amount ? 'Missing token amount' : undefined}
                            availableBalances={getAvailableTokens(token)}
                            onCoinsChange={(coins) => updateToken(tokenIndex, coins)}
                            onRemove={() => removeToken(tokenIndex)}
                        />)}
                    <Button
                        className='add-token-button'
                        size='small'
                        buttonType='secondary'
                        disabled={getAvailableTokens().length <= 0}
                        onClick={addToken}
                    >
                        Add Token
                    </Button>
                </div>
            </DialogContent>
            <DialogFooter className='dialog-footer section small'>
                <TransactionFee
                    fee={{ label: 'Transaction fee', value: txState.fee?.coins, loading: txState.feeLoading }}
                    hideAmount={!hubWallet}
                />
            </DialogFooter>
            <DialogAction
                className='save-button'
                onClick={save}
                primary
                loading={txState.broadcasting}
                disabled={isEmpty(txState) || txState.broadcasting || txState.feeLoading || !liquidity.rollapp}
            >
                Save
            </DialogAction>
        </Dialog>
    );
};

export default RollappLiquidityDialog;
