import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import DisplayNameWithFuture from '../../currency/display-name-with-future/display-name-with-future';
import { useNetwork } from '../../network/network-context';
import { Network } from '../../network/network-types';
import { Pool } from '../types';
import './pool-entry.scss';

interface PoolEntryProps {
    pool: Pool;
    size?: 'small' | 'medium';
}

const PoolEntry: React.FC<PoolEntryProps> = ({ pool, size = 'medium' }) => {
    const navigate = useNavigate();
    const { getNetwork } = useNetwork();

    const assetsNetworks = useMemo(
        () => pool ? pool.assets.map((asset) => getNetwork(asset.networkId)) : [],
        [ getNetwork, pool ],
    );

    const renderAssetLogo = (asset: CoinsAmount, assetNetwork?: Network): ReactElement | undefined => {
        return assetNetwork &&
            <img className='pool-asset-logo' src={getCurrencyLogoPath(asset.currency, assetNetwork)} alt='currency logo' />;
    };

    return (
        <div className={classNames('pool-entry', size)}>
            {renderAssetLogo(pool.assets[0], assetsNetworks[0])}
            {renderAssetLogo(pool.assets[1], assetsNetworks[1])}
            <span className='pool-title'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='pool-assets ellipsis horizontally-centered' onClick={() => navigate(`/amm/pool/${pool.id}`)}>
                    <DisplayNameWithFuture coins={pool.assets[0]} />&nbsp;/&nbsp;<DisplayNameWithFuture coins={pool.assets[1]} />
                </a>
                <span className='pool-name'>Pool #{pool.id.toString().padStart(3, '0')}</span>
            </span>
        </div>
    );
};

export default PoolEntry;
