import { GasPrice } from 'cosmjs/packages/stargate';
import { Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { createGrantExecMessage, isQuickAuthGrantedMessages } from '../quick-auth/quick-auth-service';
import { ClientError } from '../client/client-error';
import { useWallet } from '../wallet/wallet-context';
import { WalletError } from '../wallet/wallet-error';
import { broadcastTx, sendEvmTx, signTx, simulateTx } from './tx-service';
import { TxAction, TxState, txStateReducer } from './tx-state';
import { useClient } from '../client/client-context';
import { CoinsAmount } from '../currency/currency-types';
import { TxError } from './tx-error';
import { AccountNetworkState } from '../account/account-network-state';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { EvmContractCreator, SignMethod, TxMessagesCreator, TxResponse } from './tx-types';
import { wasmTypes } from 'cosmjs/packages/cosmwasm-stargate/build/modules';

export interface TxValue {
    txState: TxState;
    broadcast: (memo?: string, params?: any, ignoreFee?: boolean) => Promise<void>;
    calculateFee: (value?: boolean, askPublicKey?: boolean) => void;
    setGasPrice: (gasPrice?: GasPrice) => void;
    clearFee: () => void;
    txStateDispatch: Dispatch<TxAction>;
}

interface TxParams {
    networkState: AccountNetworkState;
    txMessagesCreator: TxMessagesCreator;
    evmContractCreator?: EvmContractCreator;
    signMethod?: SignMethod;
    useEvm?: boolean;
}

export const useTx = ({ networkState, txMessagesCreator, signMethod, useEvm, evmContractCreator }: TxParams): TxValue => {
    const { networkWalletMap, handleWalletError } = useWallet();
    const { clientStateMap, signingClientStateMap, refreshClient, connectClient, connectSigningClient } = useClient();
    const [ txState, txStateDispatch ] = useReducer(txStateReducer, {});
    const [ gasPrice, setGasPrice ] = useState<GasPrice>();
    const cancelAndSetSimulateTxPromise = useCancelablePromise<{ gas: number, coins: CoinsAmount, gasPrice: GasPrice }>();

    const networkWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : null;
    const clientState = networkState.network ? clientStateMap[networkState.network.chainId] : null;
    const signingClientState = networkState.network ? signingClientStateMap[networkState.network.chainId] : null;

    const isClientConnectionFailed = useMemo(() => (clientState && !clientState.client && !clientState.connecting) ||
        (signingClientState && !signingClientState.client && !signingClientState.connecting), [ clientState, signingClientState ]);

    const calculateFee = useCallback((value?: boolean, askPublicKey?: boolean) => {
        if (!networkState.hexAddress || (!askPublicKey && networkWallet?.publicKeyRequired?.(networkState.hexAddress))) {
            return;
        }
        value = value ?? true;
        if (value) {
            txStateDispatch({ type: 'set-fee', payload: undefined });
        }
        txStateDispatch({ type: 'set-fee-loading', payload: value });
    }, [ networkState.hexAddress, networkWallet ]);

    const clearFee = useCallback(() => txStateDispatch({ type: 'set-fee', payload: undefined }), []);

    useEffect(() => {
        if (txState.error) {
            setTimeout(() => txStateDispatch({ type: 'set-error', payload: undefined }), 50); // todo: do it different (the error object should be paces at the specific modules)
        }
    }, [ txState.error ]);

    const broadcast = useCallback(async (memo?: string, params?: any, ignoreFeeLoading?: boolean) => {
        if (txState.broadcasting || (!ignoreFeeLoading && txState.feeLoading)) {
            return;
        }
        txStateDispatch({ type: 'set-params', payload: params });
        if (!networkWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, networkState.network));
            return;
        }
        if (!signingClientState?.client || !networkState.network || !networkState.address) {
            txStateDispatch({ type: 'set-error', payload: new TxError('MISSING_DATA', networkState.network) });
            return;
        }
        let messages = txMessagesCreator(txState.fee?.coins, params, true);
        const evmContract = evmContractCreator?.(messages);
        if (!messages.length && (!useEvm || !evmContract)) {
            txStateDispatch({ type: 'set-error', payload: new TxError('MISSING_DATA', networkState.network) });
            return;
        }
        txStateDispatch({ type: 'set-broadcasting' });
        const quickAuthClient = signingClientState.client.getQuickAuthClient();
        const quickAuthMessages = signingClientState.client.getQuickAuthMessages();
        const toUseQuickAuthClient = quickAuthClient &&
            (isQuickAuthGrantedMessages(quickAuthClient.getNetwork(), messages) ||
                messages.every((message) => quickAuthMessages?.includes(message.typeUrl)) ||
                networkWallet.getWalletType() === 'Quick Auth');
        if (networkWallet.getWalletType() === 'PortalWallet' || toUseQuickAuthClient) {
            txStateDispatch({ type: 'set-signing', payload: false });
        }
        let response: TxResponse | void | undefined;
        if (useEvm) {
            txStateDispatch({ type: 'set-signing', payload: false });
            response = await sendEvmTx({
                messages,
                client: signingClientState?.client,
                network: networkState.network,
                signerAddress: networkState.address,
                txFee: txState.fee,
                gasPrice,
                evmContract,
            }).catch((error) => txStateDispatch({ type: 'set-error', payload: error }));
        } else {
            let signerAddress = networkState.address;
            const toUseWasm = messages.some((message) => wasmTypes.some(([ type ]) => message.typeUrl === type));
            let client = toUseWasm ? signingClientState?.wasmClient : signingClientState?.client;
            let granter = undefined;
            if (toUseQuickAuthClient && quickAuthClient) {
                granter = signerAddress;
                signerAddress = (await quickAuthClient.getActiveSigner().getAccounts())?.[0]?.address;
                client = quickAuthClient;
                messages = [ createGrantExecMessage(signerAddress, messages, client?.registry) ];
            }
            if (!client) {
                txStateDispatch({ type: 'set-error', payload: new TxError('MISSING_DATA', networkState.network) });
                return;
            }
            const signedTx = await signTx({
                messages,
                client,
                network: networkState.network,
                signerAddress,
                txFee: txState.fee,
                signMethod,
                memo,
                gasPrice,
                granter,
            }).catch((error) => {
                if (error instanceof ClientError && error.code === 'SIMULATE_TX_FAILED') {
                    error.code = 'BROADCAST_TX_FAILED';
                }
                txStateDispatch({ type: 'set-error', payload: error });
            });
            if (!signedTx) {
                return;
            }
            txStateDispatch({ type: 'set-signing', payload: false });
            response = await broadcastTx(client, signedTx).catch((error) => txStateDispatch({ type: 'set-error', payload: error }));
        }
        if (response) {
            response.params = params;
            txStateDispatch({ type: 'set-response', payload: response });
            refreshClient(response.network.chainId);
            setTimeout(() => refreshClient(response!.network.chainId), 2500);
            setTimeout(() => refreshClient(response!.network.chainId), 5000);
        }
    }, [
        evmContractCreator,
        gasPrice,
        handleWalletError,
        networkState.address,
        networkState.network,
        networkWallet,
        refreshClient,
        signMethod,
        signingClientState?.client,
        signingClientState?.wasmClient,
        txMessagesCreator,
        txState.broadcasting,
        txState.fee,
        txState.feeLoading,
        useEvm,
    ]);

    useEffect(() => {
        if (!networkWallet) {
            txStateDispatch({ type: 'set-fee', payload: undefined });
            txStateDispatch({ type: 'set-broadcasting', payload: false });
        }
    }, [ networkState.network?.chainId, networkWallet, txState.broadcasting, txState.feeLoading ]);

    useEffect(() => {
        if (networkState.network) {
            connectClient(networkState.network);
        } else {
            cancelAndSetSimulateTxPromise();
        }
    }, [ cancelAndSetSimulateTxPromise, connectClient, networkState.network ]);

    useEffect(() => {
        if (networkState.network && clientState?.client) {
            connectSigningClient(networkState.network);
        }
    }, [ clientState?.client, networkState.network, connectSigningClient ]);

    useEffect(() => calculateFee(), [ gasPrice, calculateFee ]);

    useEffect(() => {
        if (isClientConnectionFailed) {
            txStateDispatch({ type: 'set-fee', payload: undefined });
            return;
        }
        if (txState.broadcasting || !txState.feeLoading || !networkState.network || !networkState.address || !signingClientState?.client) {
            return;
        }
        const messages = txMessagesCreator();
        const evmContract = evmContractCreator?.(messages);
        if (!messages.length && (!useEvm || !evmContract)) {
            return;
        }
        txStateDispatch({ type: 'set-params', payload: undefined });
        cancelAndSetSimulateTxPromise(
            simulateTx({
                messages,
                client: signingClientState?.client,
                network: networkState.network,
                signerAddress: networkState.address,
                useEvm,
                evmContract,
                gasPrice,
            }))
            .then((fee) => txStateDispatch({ type: 'set-fee', payload: fee }))
            .catch((error) => {
                txStateDispatch({ type: 'set-fee', payload: undefined });
                txStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetSimulateTxPromise,
        evmContractCreator,
        gasPrice,
        isClientConnectionFailed,
        networkState.address,
        networkState.network,
        signingClientState?.client,
        txMessagesCreator,
        txState.broadcasting,
        txState.feeLoading,
        useEvm,
    ]);

    return { txState, calculateFee, clearFee, broadcast, setGasPrice, txStateDispatch };
};
