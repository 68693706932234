import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { useNetwork } from '../../../network/network-context';
import { TxState } from '../../../tx/tx-state';
import { useTx } from '../../../tx/use-tx';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import { useIbcStatus } from '../ibc-status-context';
import { createFulfillOrderMessage } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';

export interface useEibcFulfillOrderValue {
    txState: TxState;
    broadcast: (memo?: string) => void;
    eibcFeeLoading: boolean;
}

export const useEibcFulfillOrder = (transfer: IbcTransferDetails): useEibcFulfillOrderValue => {
    const networkState = useHubNetworkState();
    const { handleWalletError } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const { hubNetwork } = useNetwork();
    const { getFixedFullTransfer } = useIbcStatus();
    const [ eibcFeeLoading, setEibcFeeLoading ] = useState(true);

    const clientState = hubNetwork?.chainId ? clientStateMap[hubNetwork.chainId] : undefined;

    const fulfillOrderMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !transfer.eibcOrderId || !transfer.eibcFee) {
            return [];
        }
        return [ createFulfillOrderMessage(transfer, networkState.address) ];
    }, [ networkState.address, transfer ]);

    const { txState, clearFee, calculateFee, broadcast } = useTx({ networkState, txMessagesCreator: fulfillOrderMessagesCreator });

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setEibcFeeLoading(false);
            return;
        }
        if (clientState?.client) {
            getFixedFullTransfer(transfer, clientState.client).finally(() => setEibcFeeLoading(false));
        }
    }, [ clientState, getFixedFullTransfer, transfer ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (networkState.address && transfer.eibcOrderId && transfer.eibcFee) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, networkState.address, transfer.eibcFee, transfer.eibcOrderId ]);

    return { txState, eibcFeeLoading, broadcast };
};
