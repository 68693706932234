import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Spinner from '../../../../shared/components/spinner/spinner';
import { AccountNetworkState } from '../../account-network-state';
import './account-nfts.scss';
import AccountNftsAmount from './account-nfts-amount/account-nfts-amount';

interface AccountBalancesProps {
    className?: string;
    networkState: AccountNetworkState;
    dataRefreshing?: boolean;
}

const AccountNfts: React.FC<AccountBalancesProps> = ({ dataRefreshing, className, networkState }) => {
    return <>
        <AccountNftsAmount networkState={networkState} />
        <div className={classNames('nfts-container', className)}>
            {(dataRefreshing || networkState.nftsLoading) ? <Spinner className='nfts-loader' /> :
                !networkState.nfts?.length && <span className='no-nfts'>No NFTs yet</span>}

            {networkState.nfts?.map((nft, nftIndex) => (
                <div className='nft-container' key={nftIndex} onClick={() => window.open(nft.image, '_blank')}>
                    <img key={nft.tokenId} src={nft.image} alt={nft.name} />
                    <span className='nft-name'>{nft.name}</span>
                </div>
            ))}
        </div>
    </>;
};

export default AccountNfts;
