import React, { createContext, ReactNode, useContext } from 'react';
import { useNetwork } from '../network/network-context';
import { AccountNetworkState } from './account-network-state';
import { useAccountNetwork } from './use-account-network';

export const HubNetworkStateContext = createContext<AccountNetworkState>({} as AccountNetworkState);

export const useHubNetworkState = (): AccountNetworkState => useContext(HubNetworkStateContext);

export const HubNetworkStateContextProvider = ({ children }: { children: ReactNode }) => {
    const { hubNetwork } = useNetwork();
    const [ hubNetworkState ] = useAccountNetwork(hubNetwork, true, true, false, { addressLoading: true, balancesLoading: true });

    return <HubNetworkStateContext.Provider value={hubNetworkState}>{children}</HubNetworkStateContext.Provider>;
};
