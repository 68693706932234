import React, { ReactNode, useMemo, useState } from 'react';
import Button, { ButtonProps } from '../../../shared/components/button/button';
import Confirm from '../../../shared/components/confirm/confirm';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { useClient } from '../../client/client-context';
import { getMaxDenomAmount } from '../../currency/currency-service';
import { useNetwork } from '../../network/network-context';
import { useWallet } from '../../wallet/wallet-context';
import { DEFAULT_SLIPPAGE_TOLERANCE, useTrade } from '../trade-context';
import './confirm-trade-button.scss';

const DISABLE_SLIPPAGE_CONFIRMATION_MAP_KEY = 'disable-slippage-confirmation-map-key';

const ConfirmTradeButton: React.FC<ButtonProps> = (props) => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const { hubNetwork } = useNetwork();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        slippageTolerance,
        currentSlippage,
        slippageToleranceMode,
        disabled,
        slippageDisabled,
        getTokensMinAmounts,
        broadcast,
    } = useTrade();
    const [ slippageAlertDialogOpen, setSlippageAlertDialogOpen ] = useState(false);
    const [ disableSlippageConfirmationMap, setDisableSlippageConfirmationMap ] =
        usePersistedState<{ [networkId: string]: boolean }>(DISABLE_SLIPPAGE_CONFIRMATION_MAP_KEY, {});

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];
    const networkWallet = hubNetwork && networkWalletMap[hubNetwork.chainId];

    const networkId = useMemo(
        () => asset1AmountTxState.coins?.networkId === hubNetwork?.chainId ?
            asset2AmountTxState.coins?.networkId : asset1AmountTxState.coins?.networkId,
        [ asset1AmountTxState.coins?.networkId, asset2AmountTxState.coins?.networkId, hubNetwork?.chainId ],
    );

    const renderSlippageAlertDialog = (): ReactNode => {
        if (!asset1AmountTxState.coins || !asset2AmountTxState.coins || (!slippageDisabled && !getTokensMinAmounts)) {
            return;
        }
        const { tokenOutMinAmount } = getTokensMinAmounts?.(asset1AmountTxState.coins, asset2AmountTxState.coins) || {};
        const fixedMinAmount = getMaxDenomAmount(Number(tokenOutMinAmount), asset2AmountTxState.coins.currency);

        return (
            <Confirm
                title='Slippage Alert'
                className='slippage-alert-confirm'
                closable
                onRequestClose={() => setSlippageAlertDialogOpen(false)}
                onConfirm={() => {
                    if (slippageDisabled && networkId) {
                        setDisableSlippageConfirmationMap((confirmationMap) => ({ ...confirmationMap, [networkId]: true }));
                    }
                    broadcast();
                }}
                content={slippageDisabled ? <>
                    <b>Attention</b>: Slippage tolerance is currently <b>disabled</b> for this IRO trade.<br />
                    To continue without slippage tolerance and hide this alert for this trade, click Confirm.<br />
                    Otherwise, to enable slippage tolerance, expand the trade box and turn on the slippage tolerance switch.
                </> : <>
                    <b>Note</b>: slippage is above your tolerance configuration.<br />
                    The minimum amount of tokens received from this trade is {fixedMinAmount} {asset2AmountTxState.coins?.currency.displayDenom}.
                </>}
                okLabel='Confirm'
                cancelLabel='Abort'
                warning
            />
        );
    };

    const confirmButtonDisabled = Boolean(
        disabled ||
        txState.broadcasting ||
        txState.feeLoading ||
        !asset1AmountTxState.coins?.amount ||
        !asset2AmountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const shouldShowSlippageAlert = useMemo(() => {
        if (slippageToleranceMode !== 'alert') {
            return false;
        }
        if (slippageDisabled) {
            return Boolean(networkId && !disableSlippageConfirmationMap[networkId]);
        }
        return Boolean(currentSlippage && currentSlippage >= (slippageTolerance ?? DEFAULT_SLIPPAGE_TOLERANCE));
    }, [ currentSlippage, disableSlippageConfirmationMap, networkId, slippageDisabled, slippageTolerance, slippageToleranceMode ]);

    return <>
        <Button
            {...props}
            loading={txState.broadcasting || txState.feeLoading}
            disabled={confirmButtonDisabled}
            onClick={() => {
                if (shouldShowSlippageAlert) {
                    setSlippageAlertDialogOpen(true);
                } else {
                    broadcast();
                }
            }}
        >
            {props.children || 'Trade'}
        </Button>

        {slippageToleranceMode === 'alert' && slippageAlertDialogOpen && renderSlippageAlertDialog()}
    </>;
};

export default ConfirmTradeButton;
