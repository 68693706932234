import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Button, { ButtonProps } from '../../../shared/components/button/button';
import Menu, { MenuAction, MenuProps, MenuRefProps } from '../../../shared/components/menu/menu';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import NavBar, { NavBarItem } from '../../../shared/components/nav-bar/nav-bar';
import { OverlayAlign } from '../../../shared/components/overlay/overlay';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { useAmm } from '../../amm/amm-context';
import { useClient } from '../../client/client-context';
import { useNetwork } from '../../network/network-context';
import NetworkSelector from '../../network/network-selector/network-selector';
import { useQuickAuth } from '../../quick-auth/quick-auth-context';
import WalletLogo from '../../wallet/wallet-logo/wallet-logo';
import { AccountNetworkState } from '../account-network-state';
import { useWallet } from '../../wallet/wallet-context';
import { useAuthUser } from '../auth-user/auth-user-context';
import SaveUserDialog from '../auth-user/save-user-dialog';
import { useHubNetworkState } from '../hub-network-state-context';
import QuickAuthDialog from '../../quick-auth/quick-dialog/quick-auth-dialog';
import AccountBalances from './account-balances/account-balances';
import AccountNfts from './account-nfts/account-nfts';
import AccountPools from './account-pools/account-pools';
import AccountStake from './account-stake/account-stake';
import ConnectQuickAuthWalletDialog from './connect-quick-auth-wallet-dialog/connect-quick-auth-wallet-dialog';
import HubWalletSelectorDialog from './hub-wallet-selector-dialog/hub-wallet-selector-dialog';
import InitPortalWalletDialog from './init-portal-wallet-dialog/init-portal-wallet-dialog';
import PinCodeDialog from './pin-code-dialog/pin-code-dialog';
import WalletAddress from './wallet-address/wallet-address';
import './account-menu.scss';

interface AccountMenuProps extends Omit<MenuProps, 'trigger'> {
    networkState: AccountNetworkState;
    menuAlign?: OverlayAlign;
    triggerSize?: ButtonProps['size'];
    trigger?: ReactElement;
    forceHubConnect?: boolean;
    hideShortAddressPrefix?: boolean;
    optionalNetworks: string[];
    onNetworkSelect?: (networkId: string, commonNetwork?: boolean) => void;
}

const ACCOUNT_WALLET_MENU_TABS = [ 'Balances', 'NFTs', 'Stake', 'Pools' ] as const;
type AccountWalletMenuTab = typeof ACCOUNT_WALLET_MENU_TABS[number];

const AccountMenu: React.FC<AccountMenuProps> = ({
    networkState,
    menuAlign,
    trigger,
    triggerSize,
    forceHubConnect = true,
    onNetworkSelect,
    optionalNetworks,
    ...otherMenuProps
}) => {
    const { isTablet, isMobile, width } = useWindowSize();
    const { hubNetwork } = useNetwork();
    const {
        generatedPinCode,
        networkWalletMap,
        networkWalletConnectingMap,
        portalWalletSourceType,
        networkWalletTypeMap,
        hubWallet,
        connectWallet,
        fetchMostSuitableWallet,
        disconnectWallet,
    } = useWallet();
    const { refreshClient } = useClient();
    const { ammState } = useAmm();
    const { saveUserDialogOpen, setSaveUserDialogOpen } = useAuthUser();
    const [ accountWalletMenuTab, setAccountWalletMenuTab ] = useState<AccountWalletMenuTab>('Balances');
    const [ hubWalletSelectorDialogOpen, setHubWalletSelectorDialogOpen ] = useState(false);
    const [ recoverPortalWalletMenuVisible, setRecoverPortalWalletMenuVisible ] = useState(false);
    const [ pinCodeDialogOpen, setPinCodeDialogOpen ] = useState(false);
    const [ initPortalWalletDialogOpen, setInitPortalWalletDialogOpen ] = useState(false);
    const [ dataRefreshing, setDataRefreshing ] = useState(false);
    const [ quickAuthDialogOpen, setQuickAuthDialogOpen ] = useState(false);
    const [ connectQuickAuthWalletDialogOpen, setConnectQuickAuthWalletDialogOpen ] = useState(false);
    const { isConnected, networkState: quickAuthNetworkState } = useQuickAuth();
    const hubNetworkState = useHubNetworkState();
    const accountWalletMenuRef = useRef<MenuRefProps>(null);
    const recoverPortalWalletMenuRef = useRef<MenuRefProps>(null);

    const connectedWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : undefined;
    const connectingWallet = networkState.network ? networkWalletConnectingMap[networkState.network.chainId] : undefined;

    const selectNetwork = useCallback((networkId: string, commonNetwork?: boolean) => {
        onNetworkSelect?.(networkId, commonNetwork);
        if (networkState.network && networkId && !networkWalletTypeMap[networkId] && hubWallet && networkId !== hubNetwork?.chainId) {
            const walletType = fetchMostSuitableWallet(networkState.network);
            if (walletType) {
                connectWallet(networkId, walletType);
            }
        }
    }, [
        connectWallet,
        fetchMostSuitableWallet,
        hubNetwork?.chainId,
        hubWallet,
        networkState.network,
        networkWalletTypeMap,
        onNetworkSelect,
    ]);

    const onRefreshData = useCallback(() => {
        refreshClient(networkState.network?.chainId || '');
        setTimeout(() => setDataRefreshing(true));
    }, [ networkState.network?.chainId, refreshClient ]);

    useEffect(() => {
        if ((accountWalletMenuTab === 'Balances' && !networkState.balancesLoading) ||
            (accountWalletMenuTab === 'NFTs' && !networkState.nftsLoading) ||
            (accountWalletMenuTab === 'Pools' && !ammState.loading)) {
            setDataRefreshing(false);
        }
    }, [ accountWalletMenuTab, ammState.loading, networkState.balancesLoading, networkState.nftsLoading ]);

    useEffect(() => {
        if (generatedPinCode) {
            setPinCodeDialogOpen(true);
        }
    }, [ generatedPinCode ]);

    useEffect(() => {
        setRecoverPortalWalletMenuVisible((value) => {
            const newValue = Boolean(portalWalletSourceType && !connectedWallet && connectingWallet === false); // find better way
            if (newValue && !value) {
                setTimeout(() => recoverPortalWalletMenuRef.current?.toggleMenu(true));
            }
            return newValue;
        });
    }, [ connectedWallet, connectingWallet, networkState.network?.chainId, portalWalletSourceType ]);

    const renderWalletAccountHeader = (): ReactElement => {
        return (
            <div className='wallet-menu-header'>
                <NetworkSelector
                    size='small'
                    walletSelectorLast
                    networkData={networkState}
                    networkSelectClassName='network-select-control'
                    walletSelectClassName='wallet-select-control'
                    className='network-selector'
                    onNetworkSelect={selectNetwork}
                    optionsMenuOpenDisabled={optionalNetworks && optionalNetworks.length <= 1}
                    isNetworkSelectable={(network) => !optionalNetworks || optionalNetworks.includes(network.chainId)}
                />

                <div className='wallet-menu-actions'>
                    {quickAuthNetworkState.network?.chainId === networkState.network?.chainId && (networkState.address || isTablet) &&
                        connectedWallet?.getWalletType() !== 'Quick Auth' && (
                            <Button
                                size='small'
                                buttonType='icon'
                                className={classNames('quick-auth-button', { connected: isConnected })}
                                iconColorMode='fill'
                                tooltip='Quick Auth'
                                onClick={() => isTablet ? setConnectQuickAuthWalletDialogOpen(true) : setQuickAuthDialogOpen(true)}
                            >
                                <ThunderIcon />
                            </Button>
                        )}

                    {quickAuthDialogOpen && networkState.network &&
                        <QuickAuthDialog networkState={networkState} onRequestClose={() => setQuickAuthDialogOpen(false)} />}

                    {connectQuickAuthWalletDialogOpen && networkState.network && (
                        <ConnectQuickAuthWalletDialog onRequestClose={() => setConnectQuickAuthWalletDialogOpen(false)} />
                    )}

                    <Button
                        buttonType='icon'
                        className='refresh-button'
                        tooltip='Refresh data'
                        onClick={onRefreshData}
                    >
                        ↻
                    </Button>
                    <Button
                        size='small'
                        buttonType='icon'
                        tooltip='Disconnect'
                        onClick={() => networkState.network && disconnectWallet(networkState.network.chainId)}
                    >
                        <LogoutIcon />
                    </Button>
                </div>
            </div>
        );
    };

    const renderTrigger = (): ReactElement | null => {
        if (trigger !== undefined) {
            return trigger;
        }
        return (
            <Button size={triggerSize} buttonType='secondary'>
                <WalletAddress networkState={hubNetworkState} hideShortAddressPrefix={isMobile && (width > 480 || width < 360)} />
            </Button>
        );
    };

    const renderConnectTrigger = (onClick?: () => void): ReactElement => {
        const isWalletConnecting = Boolean(networkState.network?.chainId && connectingWallet);
        return (
            <Button
                loading={isWalletConnecting}
                buttonType='primary'
                size={triggerSize}
                onClick={onClick}
                disabled={!networkState.network || isWalletConnecting}
            >
                <WalletLogo type={undefined} />&nbsp;&nbsp;Connect
            </Button>
        );
    };

    const renderRecoverPortalWalletMenu = (): ReactElement => {
        return <>
            <Menu
                ref={recoverPortalWalletMenuRef}
                trigger={renderConnectTrigger()}
                onClickOutside={() => !pinCodeDialogOpen && !initPortalWalletDialogOpen &&
                    recoverPortalWalletMenuRef.current?.toggleMenu(false)}
                className='connected-wallet-menu'
                overlayAlign={menuAlign}
                header={(
                    <div className='recover-portal-wallet-menu-header'>
                        Welcome back!<br />Your Portal wallet key needs to be recovered<br />
                        <Button
                            className='recover-wallet-button'
                            onClick={() => setInitPortalWalletDialogOpen(true)}
                        >
                            Recover wallet
                        </Button>
                    </div>
                )}
            >
                <MenuAction onClick={() => networkState.network && disconnectWallet(networkState.network.chainId)}>
                    <LogoutIcon />&nbsp;&nbsp;Disconnect
                </MenuAction>
            </Menu>

            {portalWalletSourceType && initPortalWalletDialogOpen && networkState.network && (
                <InitPortalWalletDialog
                    network={networkState.network}
                    recover={recoverPortalWalletMenuVisible}
                    sourceType={portalWalletSourceType}
                    onRequestClose={() => setInitPortalWalletDialogOpen(false)}
                />
            )}
        </>;
    };

    const renderConnectWalletMenu = (): ReactElement => {
        return <>
            {renderConnectTrigger(() => setHubWalletSelectorDialogOpen(true))}
            {networkState && hubWalletSelectorDialogOpen &&
                <HubWalletSelectorDialog networkState={networkState} onRequestClose={() => setHubWalletSelectorDialogOpen(false)} />}
        </>;
    };

    const renderAccountMenu = (): ReactElement => {
        return <>
            <Menu
                ref={accountWalletMenuRef}
                className='connected-wallet-menu'
                trigger={renderTrigger()}
                overlayAlign={menuAlign}
                header={<>
                    {renderWalletAccountHeader()}
                    {accountWalletMenuTab === 'Balances' &&
                        <AccountBalances
                            networkState={networkState}
                            className='wallet-account-tab'
                            dataRefreshing={dataRefreshing}
                            onBalanceClick={() => accountWalletMenuRef.current?.toggleMenu(false)}
                        />}
                    {accountWalletMenuTab === 'Stake' &&
                        <AccountStake
                            onRequestClose={() => accountWalletMenuRef.current?.toggleMenu(false)}
                            className='wallet-account-tab'
                            networkState={networkState}
                            dataRefreshing={dataRefreshing}
                            onRefreshDone={() => setDataRefreshing(false)}
                        />}
                    {accountWalletMenuTab === 'Pools' &&
                        <AccountPools
                            onRequestClose={() => accountWalletMenuRef.current?.toggleMenu(false)}
                            className='wallet-account-tab'
                            dataRefreshing={dataRefreshing}
                            networkState={networkState}
                        />}
                    {accountWalletMenuTab === 'NFTs' &&
                        <AccountNfts
                            className='wallet-account-tab no-actions'
                            networkState={networkState}
                            dataRefreshing={dataRefreshing}
                        />}
                </>}
                footer={<>
                    <NavBar className='wallet-menu-nav'>
                        {ACCOUNT_WALLET_MENU_TABS.filter((tab) =>
                            (tab !== 'Pools' || networkState.network?.type === 'Hub') &&
                            (tab !== 'NFTs' || networkState.network?.evmType === 'EVM')).map((tab) => (
                            <NavBarItem
                                key={tab}
                                label={tab}
                                onClick={() => setAccountWalletMenuTab(tab)}
                                active={accountWalletMenuTab === tab}
                                className='wallet-menu-nav-item'
                            />
                        ))}
                    </NavBar>
                </>}
                {...otherMenuProps}
            />

            {generatedPinCode && pinCodeDialogOpen &&
                <PinCodeDialog pinCode={generatedPinCode} onRequestClose={() => setPinCodeDialogOpen(false)} />}

            {saveUserDialogOpen && <SaveUserDialog onRequestClose={() => setSaveUserDialogOpen(false)} />}
        </>;
    };

    if (recoverPortalWalletMenuVisible) {
        return renderRecoverPortalWalletMenu();
    }
    if (forceHubConnect && !hubNetworkState.address) {
        return renderConnectWalletMenu();
    }
    return renderAccountMenu();
};

export default AccountMenu;
