import React from 'react';
import Alert from '../../../shared/components/alert/alert';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import IbcStatusList from '../ibc-status/ibc-status-list/ibc-status-list';
import { IbcTransferStatus } from '../ibc-status/ibc-status-types';
import { EibcClientContextProvider } from './eibc-client-context';
import EibcClientStatistics from './eibc-client-statistics/eibc-client-statistics';
import RollappLiquidityList from './rollapp-liquidity-list/rollapp-liquidity-list';
import './eibc-client-page.scss';

const CAN_FULFILL_STATUSES: IbcTransferStatus[] = [ 'EibcPending', 'Refunding' ];

const EibcClientPage: React.FC = () => {
    return (
        <div className='page eibc-client-page'>
            <PathNav>
                <PathNavItem label='Transfer' />
                <PathNavItem label='Bridge LP' />
            </PathNav>

            <EibcClientStatistics />

            <Alert type='info' className='info-box'>
                Becoming a Bridge Liquidity Provider entails risks. The reliability and competence of the delegated operator are essential.
                As an LP, you rely on the operator's ability to manage a RollApp full node, validate transactions, and fulfill orders accordingly.
                Carefully consider the operator's reputation to minimize potential risks.
            </Alert>

            <RollappLiquidityList />

            <IbcStatusList
                showAllSwitchEnabled={false}
                showStatusColumn={false}
                title='Pending Withdrawals'
                haveEibcOrder
                showFulFillAction
                statuses={CAN_FULFILL_STATUSES}
            />
        </div>
    );
};

const EibcClientPageWithContext = () => <EibcClientContextProvider><EibcClientPage /></EibcClientContextProvider>;

export default EibcClientPageWithContext;
