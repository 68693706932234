import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Option } from '../../../../../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../../../../../shared/components/form-controls/select/select';
import { CoinsAmount } from '../../../../../../currency/currency-types';
import { useDymns } from '../../../../../../dymns/dymns-context';
import { useNetwork } from '../../../../../../network/network-context';
import { fetchRollAppIdName, fetchRollAppIdNumber } from '../../../../../../network/network-service';
import { DA_LOGO_MAP, DATA_AVAILABILITY_CHAINS, DataAvailability } from '../../../../../../network/network-types';
import TransactionFee from '../../../../../../tx/transaction-fee/transaction-fee';
import { useCreateRollapp } from '../../../create-rollapp-context';
import Button from '../../../../../../../shared/components/button/button';
import ButtonsGroup from '../../../../../../../shared/components/buttons-group/buttons-group';
import FileUploader from '../../../../../../../shared/components/file-uploader/file-uploader';
import Input from '../../../../../../../shared/components/form-controls/input/input';
import Icon from '../../../../../../../shared/components/icon/icon';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { ReactComponent as EthereumLogo } from '../../../../../../../assets/logos/ethereum-logo2.svg';
import { ReactComponent as CosmWasmLogo } from '../../../../../../../assets/logos/cosm-wasm-logo.svg';
import { ReactComponent as CheckIcon } from '../../../../../../../assets/icons/success.svg';
import Spinner from '../../../../../../../shared/components/spinner/spinner';
import Tooltip from '../../../../../../../shared/components/tooltip/tooltip';
import { convertToFile } from '../../../../../../../shared/utils/file-utils';
import { findAvailableRollappIdNumber } from '../../../create-rollapp-service';
import { LOGO_MAX_SIZE } from '../../../types';
import './basic-details-section.scss';

const BasicDetailsSection: React.FC = () => {
    const { loading, networks } = useNetwork();
    const {
        rollapp,
        alias,
        showErrors,
        isAliasExists,
        currentStep,
        rollappLogoFile,
        rollappDataLoaded,
        aliasFee,
        da,
        setDa,
        setAlias,
        updateRollapp,
        setRollappLogoFile,
    } = useCreateRollapp();
    const { dymnsState } = useDymns();
    const [ rollappIdChanging, setRollappIdChanging ] = useState(false);
    const [ aliasChanging, setAliasChanging ] = useState(false);
    const [ rollappLogoLoading, setRollappLogoLoading ] = useState(Boolean(rollapp.logo));
    const [ initialRollAppLogo, setInitialRollAppLogo ] = useState<File>();

    useEffect(() => {
        if (rollapp.logo) {
            setRollappLogoLoading(true);
            convertToFile(rollapp.logo).then(setInitialRollAppLogo).finally(() => setRollappLogoLoading(false));
        }
    }, [ rollapp.logo ]);

    const fixedAliasFee = useMemo(
        () => currentStep === 'Register Domain' && (aliasFee.value as CoinsAmount)?.amount &&
            { ...aliasFee, label: <>Handle length fee:&nbsp;&nbsp;</> },
        [ aliasFee, currentStep ],
    );

    const onAliasChange = useCallback((value: string, previousValue: string) => {
        if (/[^a-zA-Z0-9]/.test(value)) {
            return previousValue;
        }
        const rollappIdName = fetchRollAppIdName(rollapp.chainId);
        if (!rollappIdName || rollappIdName === previousValue) {
            setRollappIdChanging(true);
        }
        setAliasChanging(true);
        return value.toLowerCase();
    }, [ rollapp.chainId ]);

    const onAliasTypeFinish = useCallback((value: string) => {
        if (rollappIdChanging) {
            if (!value) {
                updateRollapp('chainId', '');
            } else {
                const currentRollappIdNumber = fetchRollAppIdNumber(rollapp.chainId);
                const availableRollappIdNumber = currentRollappIdNumber || findAvailableRollappIdNumber(networks);
                updateRollapp('chainId', `${value}_${availableRollappIdNumber}-1`);
            }
        }
        setRollappIdChanging(false);
        setAliasChanging(false);
        setAlias(value);
    }, [ networks, rollapp.chainId, rollappIdChanging, setAlias, updateRollapp ]);

    const canEditRegistrationData = useMemo(
        () => !rollapp.sealed && rollappDataLoaded && currentStep === 'Register Domain',
        [ currentStep, rollapp.sealed, rollappDataLoaded ],
    );

    const invalidRollAppId = useMemo(() => {
        if (!canEditRegistrationData) {
            return false;
        }
        if (!rollapp.chainId) {
            return showErrors ? 'RollApp ID is required' : '';
        }
        if (!/^[a-z]+_\d+-\d+$/.test(rollapp.chainId)) {
            return 'Follow this pattern: [lowercase-text]_[number]-[number]';
        }
        const rollappIdNumber = fetchRollAppIdNumber(rollapp.chainId);
        const rollappIdName = fetchRollAppIdName(rollapp.chainId);
        const isRollappIdNumberExists = networks.some((network) => fetchRollAppIdNumber(network.chainId) === rollappIdNumber);
        const isRollappIdNameExists = networks.some((network) => fetchRollAppIdName(network.chainId) === rollappIdName);
        if (rollappIdNumber.length < 4) {
            return `The number part (${rollappIdNumber}) must be at least 4 digits long.`;
        }
        if (rollappIdNumber.startsWith('0')) {
            return `The number part (${rollappIdNumber}) must not start with a zero.`;
        }
        if (isRollappIdNumberExists) {
            return `A network with the same id-number (${rollappIdNumber}) already exists.`;
        }
        if (isRollappIdNameExists) {
            return `A network with the same id-prefix (${rollappIdName}) already exists.`;
        }
    }, [ networks, canEditRegistrationData, rollapp.chainId, showErrors ]);

    const invalidAlias = useMemo(() => {
        if (!canEditRegistrationData) {
            return false;
        }
        if (!alias) {
            return showErrors ? 'Domain is required' : '';
        }
        if (isAliasExists) {
            return `A network with the same domain already exists.`;
        }
    }, [ canEditRegistrationData, alias, isAliasExists, showErrors ]);

    const renderRollAppIdSuffix = (): ReactNode => {
        if (!rollapp.chainId && !rollappIdChanging) {
            return undefined;
        }
        if (rollappIdChanging || loading) {
            return <Spinner size='small' />;
        }
        if (!invalidRollAppId) {
            return <Icon className='check-icon'><CheckIcon /></Icon>;
        }
    };

    const renderAliasSuffix = (): ReactNode => {
        if (!alias) {
            return undefined;
        }
        if (aliasChanging || (dymnsState.aliasesMap === undefined && dymnsState.aliasesLoading)) {
            return <Spinner size='small' />;
        }
        if (!invalidAlias) {
            return <Icon className='check-icon'><CheckIcon /></Icon>;
        }
    };

    return (
        <div className='basic-details-section section'>
            <h5 className='section-header'>Basic Details</h5>

            <div className='controls-row section-content'>
                <div className='control-container image-uploader-container'>
                    <div className='control-label-container'>
                        <label className='required'>Upload RollApp Logo</label>
                    </div>
                    <FileUploader
                        className='image-uploader'
                        acceptImages
                        circleImage
                        hideFileName
                        disabled={rollappLogoLoading}
                        loading={rollappLogoLoading}
                        maxHeight={LOGO_MAX_SIZE}
                        maxWidth={LOGO_MAX_SIZE}
                        error={showErrors && (!rollappLogoFile && !rollapp.logo) && 'RollApp logo is required'}
                        onFileSelect={setRollappLogoFile}
                        initialFile={initialRollAppLogo}
                    />
                </div>

                <div className='controls-group'>
                    <div className='controls-row'>
                        <div className='control-container domain-control-container'>
                            <div className='control-label-container'>
                                <label className='required'>Domain</label>
                                <InfoIndicator indicatorSize='small'>
                                    The RollApp domain is a unique, human-readable identifier for your chain ID.
                                    Domains are priced according to their length, 10+ characters hold the minimum price.
                                </InfoIndicator>
                            </div>
                            <Input
                                required
                                value={alias}
                                disabled={!canEditRegistrationData}
                                onValueChange={onAliasChange}
                                onTypeFinish={onAliasTypeFinish}
                                suffix={renderAliasSuffix()}
                                prefix='@'
                                typeFinishDelay={500}
                                maxLength={32}
                                error={invalidAlias}
                            />
                            {fixedAliasFee && !isAliasExists && (
                                <div className='transaction-fee-container'>
                                    <TransactionFee fee={fixedAliasFee} />
                                    <InfoIndicator tooltipPlacement='top' indicatorSize='xs'>
                                        RollApp domain handle costs are burned and are determined by length.
                                        Handles with six characters or less incur a premium fee.
                                    </InfoIndicator>
                                </div>
                            )}
                        </div>

                        <div className='control-container'>
                            <div className='control-label-container'>
                                <label className='required'>RollApp ID</label>
                                <InfoIndicator indicatorSize='small'>
                                    Unique identifier representing your RollApp on Dymension. Never re use this ID.<br />
                                    Think of it as your RollApp domain in the following format <b><code>name_number-version.</code></b>
                                </InfoIndicator>
                            </div>
                            <Tooltip
                                className='rollapp-id-warning-tooltip'
                                title='Warning, do not recycle RollApp IDs, use different IDs for every instance you deploy.'
                            >
                                <Input
                                    required
                                    disabled={!canEditRegistrationData || (!alias && !rollapp.chainId)}
                                    value={rollapp.chainId}
                                    onValueChange={() => setRollappIdChanging(true)}
                                    onTypeFinish={(value) => {
                                        setRollappIdChanging(false);
                                        updateRollapp('chainId', value);
                                    }}
                                    maxLength={50}
                                    placeholder='e.g., xyz_1234-1'
                                    suffix={renderRollAppIdSuffix()}
                                    typeFinishDelay={500}
                                    error={invalidRollAppId}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <div className='controls-row'>
                        <div className='control-container'>
                            <div className='control-label-container'>
                                <label className='required'>Display Name</label>
                            </div>
                            <Input
                                value={rollapp.chainName}
                                maxLength={32}
                                onValueChange={(value) => updateRollapp('chainName', value as string)}
                                error={showErrors && !rollapp.chainName?.trim() && 'Display name is required'}
                            />
                        </div>

                        {process.env.REACT_APP_ENV === 'core' && (
                            <div className='control-container da-control-container'>
                                <div className='control-label-container'>
                                    <label className='required'>Data Availability</label>
                                    <InfoIndicator indicatorSize='small'>
                                        Your RollApp will post its data to this network with its native token.<br />
                                        <b>IMPORTANT NOTE</b>, In any source of validity check (e.g a fraud proof) Dymension will rely on this network as the source of truth for data.
                                    </InfoIndicator>
                                </div>
                                <Select
                                    value={da}
                                    error={showErrors && !da ? 'Data Availability is required' : ''}
                                    className='da-selector'
                                    placeholder='Select DA'
                                    onSelect={(value) => setDa(value as DataAvailability)}
                                    controlSize='medium'
                                >
                                    {DATA_AVAILABILITY_CHAINS.map((da) => (
                                        <Option key={da} value={da}>
                                            <img className='da-logo' src={DA_LOGO_MAP[da as DataAvailability]} alt='da logo' />&nbsp;{da}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </div>

                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Virtual Machine</label>
                            <InfoIndicator indicatorSize='small'>Choose the VM platform for your RollApp.</InfoIndicator>
                        </div>
                        <ButtonsGroup className={classNames({ 'actions-selector-disabled': !canEditRegistrationData })}>
                            <Button
                                iconColorMode='original'
                                buttonType='secondary'
                                onClick={() => updateRollapp('evmType', 'EVM')}
                                className={classNames({ hover: rollapp.evmType === 'EVM' })}
                            >
                                <EthereumLogo />&nbsp;&nbsp;&nbsp;&nbsp;EVM
                            </Button>
                            <Button
                                iconColorMode='original'
                                buttonType='secondary'
                                onClick={() => updateRollapp('evmType', 'WASM')}
                                className={classNames({ hover: rollapp.evmType === 'WASM' })}
                            >
                                <CosmWasmLogo />&nbsp;&nbsp;&nbsp;&nbsp;CosmWasm
                            </Button>
                        </ButtonsGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicDetailsSection;
