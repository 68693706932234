import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Decimal } from 'cosmjs/packages/math';
import { DirectSecp256k1HdWallet, EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { timeToMilliseconds } from '../../../../shared/utils/date-utils';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useClient } from '../../../client/client-context';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { createGrantAllowanceMessages, createGrantAuthorizationMessages } from '../../../quick-auth/quick-auth-service';
import { DeliveryTxCode } from '../../../tx/tx-types';
import { useTx } from '../../../tx/use-tx';

interface UseIroQuickAuthValue {
    connect: () => void;
    revoke: () => void;
    connecting: boolean;
    isConnected: boolean;
}

const DEFAULT_FEE_GRANT_SPEND_LIMIT = '10';
const SESSION_DURATION = timeToMilliseconds({ seconds: 60 });
const MESSAGE_TYPES = [ '/dymensionxyz.dymension.iro.MsgCreatePlan', '/dymensionxyz.dymension.iro.MsgBuyExactSpend' ];

export const useIroQuickAuth = (): UseIroQuickAuthValue => {
    const { signingClientStateMap } = useClient();
    const { hubCurrency } = useNetwork();
    const networkState = useHubNetworkState();
    const [ expiration, setExpiration ] = useState<number>(0);
    const [ mnemonic, setMnemonic ] = useState<string | undefined>();
    const [ address, setAddress ] = useState<string | undefined>();
    const [ connecting, setConnecting ] = useState(false);

    const signingClientState = networkState.network && signingClientStateMap[networkState.network.chainId];

    const isConnected = useMemo(() => Boolean(mnemonic && Date.now() <= expiration), [ expiration, mnemonic ]);

    useEffect(() => {
        if (networkState.address && networkState.address !== address) {
            setMnemonic(undefined);
            setAddress(networkState.address);
        }
    }, [ address, networkState.address, setAddress, setMnemonic ]);

    const grantMessagesCreator = useCallback((
        fee?: CoinsAmount,
        params?: { address: string, expiration: number, feeGrant?: boolean },
    ): EncodeObject[] => {
        if (!networkState.network || !hubCurrency || !networkState.address || !params?.address || !params.expiration) {
            return [];
        }
        const spendLimit: Coin[] = [
            { amount: Decimal.fromUserInput(DEFAULT_FEE_GRANT_SPEND_LIMIT, hubCurrency.decimals).atomics, denom: hubCurrency.baseDenom },
        ];
        return params.feeGrant ?
            [ createGrantAllowanceMessages(networkState.address, params.address, params.expiration, spendLimit) ] :
            createGrantAuthorizationMessages(networkState.network, networkState.address, params.address, params.expiration, MESSAGE_TYPES);
    }, [ networkState.network, networkState.address, hubCurrency ]);

    const { txState, txStateDispatch, broadcast } = useTx({ networkState: networkState, txMessagesCreator: grantMessagesCreator });

    useEffect(() => {
        if (txState.error) {
            setConnecting(false);
        }
    }, [ setMnemonic, txState.error ]);

    useEffect(() => {
        if (!txState.response || !signingClientState?.client) {
            return;
        }
        if (txState.response.deliveryTxCode !== DeliveryTxCode.SUCCESS) {
            setConnecting(false);
            return;
        }
        const { expiration, signer, address, allowances, feeGrant } = txState.response.params || {};
        if (!feeGrant && (signer || allowances)) {
            broadcast(undefined, { signer, address, expiration, allowances, feeGrant: true }, true).then();
        } else if (signer && expiration) {
            setMnemonic(signer.mnemonic);
            setExpiration(expiration);
            signingClientState.client.setQuickAuthSigner(signer)
                .then(() => signingClientState.client?.setQuickAuthMessages(MESSAGE_TYPES))
                .finally(() => setConnecting(false));
        } else {
            setMnemonic(undefined);
            setConnecting(false);
        }
    }, [ broadcast, signingClientState?.client, txState.response ]);

    useEffect(() => {
        setTimeout(() => setMnemonic(undefined), Math.max(0, expiration - Date.now()));
    }, [ expiration, setMnemonic ]);

    useEffect(() => {
        if (!signingClientState?.client || !networkState.network) {
            return;
        }
        if (!isConnected || !mnemonic) {
            setMnemonic(undefined);
            signingClientState.client.setQuickAuthSigner().then();
            signingClientState.client.setQuickAuthMessages();
            return;
        }
    }, [ isConnected, mnemonic, networkState.network, setMnemonic, signingClientState?.client ]);

    useEffect(() => {
        if (!isConnected) {
            setExpiration(0);
            setMnemonic(undefined);
        }
    }, [ isConnected, setExpiration, setMnemonic ]);

    const connect = useCallback(() => {
        if (!networkState.network) {
            return;
        }
        setConnecting(true);
        const expiration = Date.now() + SESSION_DURATION;
        DirectSecp256k1HdWallet.generate(12, { prefix: networkState.network.bech32Prefix })
            .then(async (signer) => {
                const address = (await signer.getAccounts())?.[0]?.address;
                return broadcast(undefined, { signer, address, expiration }, true);
            })
            .catch(() => setConnecting(false));
    }, [ broadcast, networkState.network ]);

    const revoke = useCallback(() => {
        setMnemonic(undefined);
        setConnecting(false);
        setExpiration(0);
        signingClientState?.client?.setQuickAuthMessages();
        txStateDispatch({ type: 'set-response', payload: undefined });
    }, [ signingClientState?.client, txStateDispatch ]);

    return { connect, revoke, connecting, isConnected };
};
