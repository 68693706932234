import { IbcTransferDetails } from '../ibc-transfer/ibc-status/ibc-status-types';
import { Network } from '../network/network-types';
import { CoinsAmount, NFTToken } from '../currency/currency-types';

export interface AccountNetworkState {
    network?: Network;
    address?: string;
    hexAddress?: string;
    balances?: CoinsAmount[];
    nfts?: NFTToken[];
    claimableTransfers?: IbcTransferDetails[];
    nftsLoading?: boolean;
    addressLoading?: boolean;
    balancesLoading?: boolean;
    claimableTransfersLoading?: boolean;
    error?: any;
    balancesFetchError?: any;
}

export type AccountNetworkAction =
    { type: 'set-network', payload?: Network | undefined } |
    { type: 'set-address-loading', payload?: boolean } |
    { type: 'set-nfts-loading', payload?: boolean } |
    { type: 'set-address', payload: { address?: string, hexAddress?: string } | undefined } |
    { type: 'set-balances-loading', payload?: boolean } |
    { type: 'set-claimable-transfers-loading', payload?: boolean } |
    { type: 'set-balances', payload: CoinsAmount[] | undefined } |
    { type: 'set-nfts', payload: NFTToken[] | undefined } |
    { type: 'set-claimable-transfers', payload: IbcTransferDetails[] | undefined } |
    { type: 'set-balances-fetch-error', payload: any } |
    { type: 'set-error', payload: any } |
    { type: 'clear-data' }

export const ACCOUNT_NETWORK_DEFAULTS: AccountNetworkState = {
    address: undefined,
    hexAddress: undefined,
    balances: undefined,
    claimableTransfers: undefined,
    balancesLoading: false,
    addressLoading: false,
    nftsLoading: false,
    claimableTransfersLoading: false,
};

export const accountNetworkReducer = (state: AccountNetworkState, action: AccountNetworkAction): AccountNetworkState => {
    switch (action.type) {
        case 'set-network':
            if (action.payload?.chainId === state.network?.chainId) {
                return state;
            }
            return { ...state, network: action.payload, ...(state.network ? ACCOUNT_NETWORK_DEFAULTS : undefined) };
        case 'set-address-loading':
            return { ...state, addressLoading: action.payload ?? true };
        case 'set-address':
            return { ...state, ...action.payload, addressLoading: false };
        case 'set-balances-loading':
            return { ...state, balancesLoading: action.payload ?? true };
        case 'set-nfts-loading':
            return { ...state, nftsLoading: action.payload ?? true };
        case 'set-claimable-transfers-loading':
            return { ...state, claimableTransfersLoading: action.payload ?? true };
        case 'set-balances':
            return { ...state, balances: action.payload, balancesLoading: false };
        case 'set-nfts':
            return { ...state, nfts: action.payload, nftsLoading: false };
        case 'set-claimable-transfers':
            return { ...state, claimableTransfers: action.payload, claimableTransfersLoading: false };
        case 'set-error':
            return { ...state, error: action.payload };
        case 'set-balances-fetch-error':
            return { ...state, balancesFetchError: action.payload, error: action.payload };
        case 'clear-data':
            return { ...state, ...ACCOUNT_NETWORK_DEFAULTS };
        default:
            return state;
    }
};

