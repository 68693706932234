import React from 'react';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import IbcStatusList from './ibc-status-list/ibc-status-list';

const IbcStatusListPage: React.FC = () => {
    return (
        <div className='page'>
            <PathNav>
                <PathNavItem label='Transfer' />
                <PathNavItem label='History' />
            </PathNav>
            <IbcStatusList />
        </div>
    );
};

export default IbcStatusListPage;
