import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Spinner from '../../../shared/components/spinner/spinner';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { useAsset } from '../../asset/asset-context';
import TotalTvlChart from '../../network/statistics/charts/total-tvl-chart';
import StickableNetworkComments from '../../network/network-comments/stickable-network-comments/stickable-network-comments';
import IbcTransfersChart from '../../network/statistics/charts/ibc-transfers-chart/ibc-transfers-chart';
import MarketCapChart from '../../network/statistics/charts/market-cap-chart/market-cap-chart';
import TotalSupplyChart from '../../network/statistics/charts/total-supply-chart/total-supply-chart';
import NetworkApps from '../network-apps/network-apps';
import { useNetworkDashboard } from '../network-dashboard-context';
import NetworkSponsorship from '../network-sponsorship/network-sponsorship';
import NetworkCharts from './network-charts/network-charts';
import NetworkInfo from './network-info/network-info';
import NetworkStatistics from './network-statistics';
import SequencerInfo from './sequencer-info/sequencer-info';
import './network-summary.scss';

const CHART_OPTIONS = [ 'Market Cap', 'Total TVB', 'Total Supply', 'IBC Transfers' ] as const;
type ChartOption = typeof CHART_OPTIONS[number];

const SMALL_SCREEN_WIDTH = 1280;

const NetworkSummary: React.FC = () => {
    const { width, isTablet } = useWindowSize();
    const { mainAssetMap, loading } = useAsset();
    const { network, analyticsState } = useNetworkDashboard();
    const [ activeChart, setActiveChart ] = useState<ChartOption>('Market Cap');

    const nativeAsset = useMemo(() => mainAssetMap?.[network.chainId], [ mainAssetMap, network.chainId ]);

    const disabledCharts = useMemo((): ChartOption[] => {
        if (network.tokenless) {
            return [ 'Total Supply', 'Market Cap' ];
        }
        if (network.status === 'IRO') {
            const options: ChartOption[] = [ 'IBC Transfers', 'Total TVB' ];
            if (nativeAsset?.futureIRO) {
                options.push('Market Cap');
            }
            return options;
        }
        if (!nativeAsset) {
            return CHART_OPTIONS.filter((option) => option !== 'Total Supply');
        }
        return [];
    }, [ nativeAsset, network.status, network.tokenless ]);

    const selectedChart = useMemo(() => !disabledCharts.includes(activeChart) ?
        activeChart : CHART_OPTIONS.find((option) => !disabledCharts.includes(option)), [ activeChart, disabledCharts ]);

    return (
        <div className={classNames('network-summary', { hub: network.type === 'Hub', 'no-sequencer': !network.sequencer })}>
            <NetworkStatistics
                className='network-statistics-cards'
                vertically={width > SMALL_SCREEN_WIDTH}
                wide={network.type === 'RollApp' && width <= SMALL_SCREEN_WIDTH}
            />

            {network.type === 'Hub' ? (
                <TotalSupplyChart className='network-chart' showSupplyDivisionChart />
            ) : !network.tokenless && ((!network.totalSupply && !nativeAsset) || (!mainAssetMap && loading)) ? (
                <div className='network-chart section small horizontally-centered'>
                    {network.totalSupply ?
                        <Spinner className='margin-horizontally-centered' /> :
                        <span className='margin-horizontally-centered secondary-text'>No Charts Data</span>}
                </div>
            ) : selectedChart === 'Total Supply' ? (
                <TotalSupplyChart
                    chartOptions={[ ...CHART_OPTIONS ]}
                    selectedChart={selectedChart}
                    disabledOptions={disabledCharts}
                    onChartSelect={(value) => setActiveChart(value as ChartOption)}
                    className='network-chart'
                />
            ) : selectedChart === 'Market Cap' ? (
                <MarketCapChart
                    className='network-chart'
                    chartOptions={[ ...CHART_OPTIONS ]}
                    selectedChart={selectedChart}
                    disabledOptions={disabledCharts}
                    onChartSelect={(value) => setActiveChart(value as ChartOption)}
                />
            ) : selectedChart === 'Total TVB' ? (
                <TotalTvlChart
                    className='network-chart'
                    network={network}
                    state={analyticsState}
                    chartOptions={[ ...CHART_OPTIONS ]}
                    selectedChart={selectedChart}
                    disabledOptions={disabledCharts}
                    onChartSelect={(value) => setActiveChart(value as ChartOption)}
                />
            ) : (
                <IbcTransfersChart
                    className='network-chart'
                    chartOptions={[ ...CHART_OPTIONS ]}
                    selectedChart={selectedChart}
                    disabledOptions={disabledCharts}
                    onChartSelect={(value) => setActiveChart(value as ChartOption)}
                />
            )}

            {network.type === 'RollApp' && <>
                <StickableNetworkComments className='network-community' sticky={!isTablet}>
                    <h5 className='subtitle community-title'>Community</h5>
                    <NetworkSponsorship className='network-sponsorship' />
                </StickableNetworkComments>
            </>}

            <div className='network-info-section'>
                <h5 className='subtitle'>Info</h5>
                <NetworkInfo network={network} />
            </div>

            {network.type === 'RollApp' && network.sequencer && network.status !== 'IRO' && (
                <div className='sequencer-info-section'>
                    <h5 className='subtitle'>Sequencer</h5>
                    <SequencerInfo sequencer={network.sequencer} />
                </div>
            )}

            <div className='network-middle-section'>
                {network.type === 'RollApp' ? <>
                    <h5 className='subtitle'>Apps</h5>
                    <NetworkApps />
                    <h5 className='subtitle'>Tokenomics</h5>
                    <div className='section no-data'>
                        Soon
                    </div>
                </> : <>
                    <h5 className='subtitle'>Charts</h5>
                    <NetworkCharts />
                </>}
            </div>
        </div>
    );
};

export default NetworkSummary;
