import React, { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confirm from '../../../../shared/components/confirm/confirm';
import Dialog, { DialogAction, DialogContent, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Icon from '../../../../shared/components/icon/icon';
import Spinner from '../../../../shared/components/spinner/spinner';
import Stepper, { Step } from '../../../../shared/components/stepper/stepper';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/success.svg';
import { getFeeCurrency, getMaxDenomAmount } from '../../../currency/currency-service';
import { useIRO } from '../../../iro/iro-context';
import { convertFromBondingCurve, getBuyCostFormBondingCurve } from '../../../iro/iro-service';
import { useNetwork } from '../../../network/network-context';
import PathNav, { PathNavItem } from '../../../path-nav/path-nav';
import { Fee } from '../../../tx/tx-types';
import useHandleTxResponses from '../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../wallet/wallet-context';
import { CreateRollappContextProvider, useCreateRollapp } from './create-rollapp-context';
import GenesisValidatorModal from './genesis-validator/genesis-validator-modal';
import IroCurveSection from './sections/iro/bonding-curve/iro-curve-section';
import IroDistributionPlanSection from './sections/iro/distribution-plan/iro-distribution-plan-section';
import IroAllocationInfo from './sections/iro/iro-allocation-info/iro-allocation-info';
import IroInitialPurchaseSection from './sections/iro/iro-initial-purchase/iro-initial-purchase-section';
import AccountsSection from './sections/tokenomics/accounts/accounts-section';
import ActionSection from './sections/action-action/action-section';
import AppsSection from './sections/registration/apps/apps-section';
import BasicDetailsSection from './sections/registration/basic-details/basic-details-section';
import IroPlanSection from './sections/iro/iro-plan/iro-plan-section';
import SupplyAllocationSection from './sections/tokenomics/supply-allocation/supply-allocation-section';
import DistributionSection from './sections/tokenomics/distribution/distribution-section';
import MetadataSection from './sections/registration/metadata/metadata-section';
import SequencerSection from './sections/sequencer/sequencer-section';
import TokenInfoSection from './sections/tokenomics/token-info/token-info-section';
import { CREATE_ROLLAPP_STEPS, DEFAULT_DENOM_EXPONENT } from './types';
import './create-rollapp-page.scss';

const CreateRollappPage: React.FC = () => {
    const { id: rollappIdParam } = useParams();
    const { hubWallet } = useWallet();
    const { hubNetwork, loading: networksLoading } = useNetwork();
    const [ iroConfirmationOpen, setIroConfirmationOpen ] = useState(false);
    const { iroParams } = useIRO();
    const navigate = useNavigate();
    const {
        rollapp,
        currentStep,
        rollappTxState,
        iroPlanTxState,
        visibleStep,
        genesisGenerating,
        token,
        isComplete,
        rollappDataLoaded,
        iroQuickAuthConnecting,
        logoUploading,
        aliasFee,
        genesisValidating,
        iro,
        haveIRO,
        iroLaunchedModalVisible,
        setIroLaunchedModalVisible,
        setCurrentStep,
        setVisibleStep,
        saveRollapp,
        launchIro,
    } = useCreateRollapp();
    useHandleTxResponses(rollappTxState, hubWallet);
    useHandleTxResponses(iroPlanTxState, hubWallet);

    const renderRegisterRollappStep = (): ReactElement => {
        const isRegistration = currentStep === 'Register Domain';
        const feeCurrency = hubNetwork && getFeeCurrency(hubNetwork);

        const fees: Fee[] = !feeCurrency ? [] : [
            {
                label: 'Transaction fee',
                loading: rollappTxState.feeLoading,
                value: rollappTxState.fee?.coins || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
            },
            isRegistration ? aliasFee : undefined,
        ].filter(Boolean) as Fee[];

        return <>
            <BasicDetailsSection />
            <MetadataSection />
            <AppsSection />
            <ActionSection
                label={isRegistration ? 'Register' : 'Update'}
                callback={saveRollapp}
                loading={networksLoading}
                callbackLoading={rollappTxState.broadcasting || genesisGenerating || logoUploading}
                header={`${isRegistration ? 'Register' : 'Update'} the RollApp`}
                description={
                    isRegistration ?
                        `This transaction creates a new RollApp instance on the Dymension network, assigning it the selected domain. You will become the RollApp's creator, owning the domain and having the authority to modify the RollApp's metadata.` :
                        'This transaction updates the RollApp instance'}
                fees={fees}
            />
        </>;
    };

    const renderTokenomicsStep = (): ReactElement => {
        const feeCurrency = hubNetwork && getFeeCurrency(hubNetwork);
        const fees: Fee[] = !feeCurrency ? [] : [
            {
                label: 'Transaction fee',
                loading: rollappTxState.feeLoading,
                value: rollappTxState.fee?.coins || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
            },
        ];
        return <>
            {!rollappDataLoaded ? <Spinner className='tokenomics-loader' size='large' /> : <>
                <TokenInfoSection />
                <DistributionSection />
                {!token.tokenless && <>
                    <SupplyAllocationSection />
                    <AccountsSection />
                </>}
            </>}
            <ActionSection
                label={currentStep === 'Tokenomics' ? 'Generate' : 'Update'}
                callback={saveRollapp}
                loading={networksLoading}
                callbackLoading={rollappTxState.broadcasting || genesisGenerating || logoUploading}
                disabled={!rollappDataLoaded || rollapp.sealed}
                header='Generate Genesis'
                description={`In this action, you create a genesis file using the rollapp data you provided in the previous steps, and then automatically upload it to our decentralized storage. and the stored url will be saved on-chain.`}
                fees={fees}
            />
        </>;
    };

    const renderIroStep = (): ReactElement => {
        const feeCurrency = hubNetwork && getFeeCurrency(hubNetwork);
        const creationFeeAmount = getMaxDenomAmount(Number(iroParams?.creationFee) || 0, undefined, DEFAULT_DENOM_EXPONENT);
        const creationFeeDymAmount = iro.bondingCurve ?
            getBuyCostFormBondingCurve(convertFromBondingCurve(iro.bondingCurve), 0, creationFeeAmount) : 0;
        const fees: Fee[] | undefined = rollapp.sealed || !haveIRO ? undefined : !feeCurrency ? [] : [
            {
                label: 'Plan creation fee',
                loading: false,
                value: {
                    currency: feeCurrency,
                    amount: creationFeeDymAmount,
                    networkId: hubNetwork?.chainId,
                },
            },
            {
                label: 'Transaction fee',
                loading: iroPlanTxState.feeLoading,
                value: iroPlanTxState.fee?.coins || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
            },
        ];

        const onActionClick = () => {
            if (rollapp.sealed || !haveIRO) {
                setCurrentStep('Set Operator');
                setVisibleStep('Set Operator');
            } else {
                setIroConfirmationOpen(true);
            }
        };

        return <>
            <IroAllocationInfo />
            {haveIRO ? <>
                <IroPlanSection />
                {!rollapp.preLaunchTime && <IroInitialPurchaseSection />}
                <IroDistributionPlanSection />
                <IroCurveSection />
            </> : undefined}
            <ActionSection
                label={haveIRO ? 'Launch' : 'Skip'}
                callback={onActionClick}
                header='Launch the IRO'
                disabled={!rollappDataLoaded || rollapp.sealed}
                callbackLoading={iroPlanTxState.broadcasting || genesisGenerating || logoUploading || iroQuickAuthConnecting}
                description={'Proceeding with this transaction will initiate the IRO. This action is irreversible and cannot be modified afterward. Please review all settings carefully before confirming.'}
                fees={fees}
            />
            {iroConfirmationOpen && (
                <Confirm
                    onConfirm={launchIro}
                    title='Launch the IRO'
                    content='This action is irreversible. Are you sure you want to continue?'
                    onCancel={() => setIroConfirmationOpen(false)}
                    onRequestClose={() => setIroConfirmationOpen(false)}
                    okLabel='Launch'
                    cancelLabel='Edit Settings'
                />
            )}
        </>;
    };

    const renderSetSequencerStep = (): ReactElement => {
        const feeCurrency = hubNetwork && getFeeCurrency(hubNetwork);
        const fees: Fee[] = !feeCurrency ? [] : [
            {
                label: 'Transaction fee',
                loading: rollappTxState.feeLoading,
                value: rollappTxState.fee?.coins || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
            },
        ];
        return <>
            <SequencerSection />
            <ActionSection
                label={isComplete ? 'Update' : 'Set'}
                callback={saveRollapp}
                callbackLoading={rollappTxState.broadcasting || genesisGenerating || logoUploading}
                header='Set Operator'
                disabled={rollapp.launched}
                description='Completing the Set Operator transaction will enable the selected operator to activate your RollApp at their discretion. Once the operator is active, editing the operator details and other RollApp settings will no longer be possible. Please review all details carefully before proceeding.'
                fees={fees}
            />
        </>;
    };

    const renderCurrentVisibleStep = (): ReactElement => {
        if (!currentStep) {
            return <Spinner className='margin-horizontally-centered' size='large' />;
        }
        if (visibleStep === 'Tokenomics') {
            return renderTokenomicsStep();
        } else if (visibleStep === 'Launch IRO') {
            return renderIroStep();
        } else if (visibleStep === 'Set Operator') {
            return renderSetSequencerStep();
        }
        return renderRegisterRollappStep();
    };

    const renderLaunchedIroModal = (): ReactElement => {
        return (
            <Dialog className='launched-iro-modal' onRequestClose={() => setIroLaunchedModalVisible(false)}>
                <DialogTitle className='horizontally-centered'>
                    <Icon className='modal-title-icon'><CheckIcon /></Icon> IRO Successfully Launched!
                </DialogTitle>
                <DialogContent className='modal-content'>
                    <p>The Initial RollApp Offering (IRO) is now live and open for participation.</p><br />
                    <p>The final step ({CREATE_ROLLAPP_STEPS[3]}) can be done at any time, but your RollApp <b>can</b> only launch once this step is completed.
                    </p>
                </DialogContent>
                <DialogAction close className='modal-action' secondary>Set Operator</DialogAction>
                <DialogAction
                    onClick={() => navigate(`/rollapps/${rollapp.chainId}/token`)}
                    className='modal-action'
                    primary
                >
                    Visit IRO Page
                </DialogAction>
            </Dialog>
        );
    };

    return (
        <div className='create-rollapp-page page'>
            <PathNav>
                <PathNavItem label='RollApps' url='/rollapps' />
                <PathNavItem label='Manage' url='/rollapps/manage' />
                <PathNavItem copyable={Boolean(rollappIdParam)} label={rollappIdParam || 'New RollApp'} />
            </PathNav>
            {!rollapp.launched && (
                <Stepper
                    currentStep={isComplete ? CREATE_ROLLAPP_STEPS.length : Math.max(0, CREATE_ROLLAPP_STEPS.indexOf(currentStep))}
                    visibleStep={Math.max(0, CREATE_ROLLAPP_STEPS.indexOf(visibleStep))}
                    className='create-rollapp-stepper section'
                    onVisibleStepChange={(visibleStep) => {
                        const step = CREATE_ROLLAPP_STEPS[Math.max(0, Math.min(visibleStep, CREATE_ROLLAPP_STEPS.length - 1))];
                        setVisibleStep(step);
                    }}
                >
                    {CREATE_ROLLAPP_STEPS.map((step) => <Step key={step} label={step} />)}
                </Stepper>
            )}
            {renderCurrentVisibleStep()}

            {iroLaunchedModalVisible && renderLaunchedIroModal()}

            {genesisValidating && <GenesisValidatorModal />}
        </div>
    );
};

const CreateRollappPageWithContext = () =>
    <CreateRollappContextProvider><CreateRollappPage /></CreateRollappContextProvider>;

export default CreateRollappPageWithContext;
