import classNames from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent } from '../../../../shared/components/dialog/dialog';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as ArrowUpRight } from '../../../../assets/icons/arrow-up-right.svg';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import { convertDecimalToInt, formatNumber, formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import { getCurrencyLogoPath, getMaxDenomAmount, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import DisplayNameWithFuture from '../../../currency/display-name-with-future/display-name-with-future';
import IncentiveBadge from '../../../incentives/incentive-badge/incentive-badge';
import { useIncentives } from '../../../incentives/incentives-context';
import { useNetwork } from '../../../network/network-context';
import { useSponsorship } from '../../../sponsorship/sponsorship-context';
import { getPositionPart } from '../../amm.service';
import { Asset } from '../../../asset/asset-types';
import LiquidityDialog, { LiquidityDialogProps } from '../../liquidity-dialog/liquidity-dialog';
import { useAmm } from '../../amm-context';
import { Pool } from '../../types';
import PoolApr from '../pool-apr/pool-apr';
import './pool-list.scss';

export interface PoolListProps {
    positions?: boolean;
    asset?: Asset;
}

export const PoolListHeader: React.FC<{ children: ReactNode, className?: string }> = ({ children, className }) => {
    return <h5 className={classNames('pool-list-header', className)}>{children}</h5>;
};

const PoolList: React.FC<PoolListProps> = ({ positions, asset }) => {
    const navigate = useNavigate();
    const { getNetwork } = useNetwork();
    const { getPoolLiquidity, ammState, sortedFilteredPools } = useAmm();
    const { sponsorshipPoolAprs } = useSponsorship();
    const { incentivesState } = useIncentives();
    const [ liquidityDialogProps, setLiquidityDialogProps ] = useState<LiquidityDialogProps>();
    const [ loadingDialogVisible, setLoadingDialogVisible ] = useState(false);
    const [ currentPoolIdAndTotalShares, setCurrentPoolIdAndTotalShares ] = useState<{ id: number, shares: bigint }>();

    const pools = useMemo<Pool[]>(() => {
        if (asset) {
            return (ammState.pools || []).filter((pool) => pool.assets.some((poolAsset) => isCoinsEquals(asset, poolAsset, true)));
        }
        if (positions) {
            return (ammState.pools || []).filter((pool) => pool.position);
        }
        return sortedFilteredPools;
    }, [ ammState.pools, asset, positions, sortedFilteredPools ]);


    useEffect(() => {
        if (!loadingDialogVisible) {
            return;
        }
        const updatedPool = ammState.pools?.find((pool) => pool.id === currentPoolIdAndTotalShares?.id);
        if (updatedPool && updatedPool.totalShares !== currentPoolIdAndTotalShares?.shares) {
            setCurrentPoolIdAndTotalShares(undefined);
            setTimeout(() => {
                setLoadingDialogVisible(false);
                setLiquidityDialogProps({ pool: updatedPool, type: 'Bond' });
            }, 500);
        }
        setTimeout(() => {
            setCurrentPoolIdAndTotalShares(undefined);
            setLoadingDialogVisible(false);
        }, 5000);
    }, [ ammState.pools, currentPoolIdAndTotalShares, loadingDialogVisible ]);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = getNetwork(asset.networkId);

        return currencyNetwork &&
            <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='currency logo' />;
    };

    const onLiquidityActionClick = (event: React.MouseEvent, pool: Pool): void => {
        event.stopPropagation();
        setLiquidityDialogProps({ pool, type: 'Add' });
        setCurrentPoolIdAndTotalShares({ id: pool.id, shares: pool.totalShares });
    };

    const renderPoolIdColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn className='index-column' contentClassName='index-column-content'>
                #{pool.id.toString().padStart(3, '0')}
            </TableColumn>
        );
    };

    const renderPoolNameColumn = (pool: Pool): ReactElement => {
        const haveIncentives = sponsorshipPoolAprs[pool.id] ||
            incentivesState.incentives?.[pool.lpTokenDenom]?.some((incentive) => incentive.coins.length);
        return (
            <TableColumn>
                {renderAssetLogo(pool.assets[0])}
                {renderAssetLogo(pool.assets[1])}
                <span className='pool-assets-names'>
                    <DisplayNameWithFuture coins={pool.assets[0]} />&nbsp;/&nbsp;<DisplayNameWithFuture coins={pool.assets[1]} />
                    {haveIncentives ? <IncentiveBadge className='incentives-badge' size='small' denom={pool.lpTokenDenom} /> : undefined}
                </span>
            </TableColumn>
        );
    };

    const renderPositionLiquidityColumn = (pool: Pool): ReactElement => {
        const liquidity = getPoolLiquidity(pool) || 0;
        const sharesPart = getPositionPart(pool);

        return (
            <TableColumn align='right'>
                {ammState.paramsLoading ?
                    <Spinner size='small' /> : formatPrice(liquidity * sharesPart, undefined, { notation: 'compact' })}
            </TableColumn>
        );
    };

    const renderPoolLiquidityColumn = (pool: Pool): ReactElement => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;

        return (
            <TableColumn align='right'>
                {formatPrice(poolLiquidity, undefined, { notation: 'compact' })}
            </TableColumn>
        );
    };

    const renderBondedLiquidityColumn = (pool: Pool): ReactElement => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        const totalLocked = ammState.totalLockedValues?.[pool.lpTokenDenom] || 0;
        return (
            <TableColumn align='right'>
                {ammState.totalLockedValues === undefined && ammState.totalLockedValuesLoading ? <Spinner size='small' /> : <>
                    {formatPrice(totalLocked, undefined, { notation: 'compact' })}
                    {ammState.totalLockedValuesLoading}
                    <span className='bonded-percentage'>({roundNumber(totalLocked * 100 / poolLiquidity, 2)}%)</span>
                </>}
            </TableColumn>
        );
    };

    const renderTradingVolumeColumn = (pool: Pool): ReactElement => {
        const volume = !ammState.params?.vsCoins ? 0 : getMaxDenomAmount(
            (pool.volume?.value || 0) - (pool.volume?.previousDayValue || 0), ammState.params.vsCoins.currency);

        return <TableColumn align='right'>{formatPrice(volume, undefined, { notation: 'compact' })}</TableColumn>;
    };

    const renderAprColumn = (pool: Pool): ReactElement => {
        return <TableColumn align='right'><PoolApr pool={pool} /></TableColumn>;
    };

    const renderSharesColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(convertDecimalToInt(Number(pool.position?.shares) || 0))}
            </TableColumn>
        );
    };

    const renderBondSharesColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(convertDecimalToInt(Number(pool.position?.bondedShares) || 0))}
                <span className='bonded-percentage'>
                    ({roundNumber(Number(pool.position?.bondedShares) * 100 / Number(pool.position?.shares), 2)}%)
                </span>
            </TableColumn>
        );
    };

    const renderActionsColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right' className='actions-column'>
                <Button
                    tooltipPlacement='bottom-end'
                    buttonType='secondary'
                    size='small'
                    onClick={(event) => onLiquidityActionClick(event, pool)}
                >
                    Add liquidity&nbsp;&nbsp;<ArrowUpRight />
                </Button>
            </TableColumn>
        );
    };

    const renderPoolHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn className='index-column' contentClassName='index-column-content'>ID</TableColumn>
                <TableColumn>Name</TableColumn>
                <TableColumn align='right'>{positions ? 'Position' : 'Liquidity'}</TableColumn>
                {positions && <TableColumn align='right'>Shares</TableColumn>}
                {positions && <TableColumn align='right' nowrap>Bonded shares</TableColumn>}
                {!positions && (
                    <TableColumn
                        align='right'
                        nowrap
                        info='Pools may be eligible for additional rewards. Enhance your earnings by bonding your LP shares. Shares unbond instantly.'
                    >
                        Bonded liquidity
                    </TableColumn>
                )}
                {!positions && <TableColumn align='right' nowrap>Volume (24h)</TableColumn>}
                <TableColumn
                    align='right'
                    info={(
                        <div className='apr-tooltip'>
                            <p>APR (Annual Percentage Rate) indicates the expected yearly yield for providing liquidity to the pool.</p>
                            <p>You can earn additional rewards by bonding your LP (Liquidity Provider) tokens.</p>
                        </div>
                    )}
                >
                    APR
                </TableColumn>
                <TableColumn align='right' className='actions-column' />
            </TableRow>
        );
    };

    const renderPoolRow = (pool: Pool): ReactElement => {
        return (
            <TableRow key={pool.id} className='pool-row' onSelect={() => navigate(`/amm/pool/${pool.id}`)}>
                {renderPoolIdColumn(pool)}
                {renderPoolNameColumn(pool)}
                {positions ? renderPositionLiquidityColumn(pool) : renderPoolLiquidityColumn(pool)}
                {positions && renderSharesColumn(pool)}
                {positions ? renderBondSharesColumn(pool) : renderBondedLiquidityColumn(pool)}
                {!positions && renderTradingVolumeColumn(pool)}
                {renderAprColumn(pool)}
                {renderActionsColumn(pool)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if ((!ammState.pools && ammState.loading) || (positions && !ammState.positions && ammState.positionsLoading)) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!pools?.length) {
            return <div className='no-data'>{positions ? 'No Positions' : 'No Pools'}</div>;
        }
    };
    return (
        <div className='pool-list-container'>
            <Table className='pool-list' firstColumnSticky bottomBar={renderBottomBar()}>
                {renderPoolHeaderRow()}
                {pools?.map(renderPoolRow)}
            </Table>

            {liquidityDialogProps &&
                <LiquidityDialog
                    {...liquidityDialogProps}
                    onRequestClose={() => setLiquidityDialogProps(undefined)}
                    onSuccess={() => {
                        if (!loadingDialogVisible && liquidityDialogProps?.type === 'Add') {
                            setLoadingDialogVisible(true);
                        } else {
                            setCurrentPoolIdAndTotalShares(undefined);
                        }
                    }}
                />}

            {loadingDialogVisible && <Dialog><DialogContent><Spinner /></DialogContent></Dialog>}
        </div>
    );
};

export default PoolList;
