import { useEffect, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { useClient } from '../../client/client-context';
import { useIRO } from '../../iro/iro-context';
import { Asset } from '../asset-types';
import { Holder } from './holder-types';
import { getHolders, getHoldersCount } from './holder.service';

interface UseHolderListValue {
    holders?: Holder[];
    totalCount?: number;
    loading: boolean;
    countLoading: boolean;
}

export const useHolderList = (asset: Asset, list?: boolean): UseHolderListValue => {
    const { handleClientError } = useClient();
    const { getIroPlan } = useIRO();
    const [ holders, setHolders ] = useState<Holder[]>();
    const [ totalCount, setTotalCount ] = useState<number>();
    const [ loading, setLoading ] = useState(true);
    const [ countLoading, setCountLoading ] = useState(true);
    const cancelAndSetHoldersCountPromise = useCancelablePromise<number>();
    const cancelAndSetHoldersPromise = useCancelablePromise<Holder[]>();

    useEffect(() => {
        if (list) {
            return;
        }
        cancelAndSetHoldersCountPromise((signal) => getHoldersCount(asset, signal))
            .then(setTotalCount)
            .catch((error) => handleClientError(error))
            .finally(() => setCountLoading(false));
    }, [ asset, cancelAndSetHoldersCountPromise, handleClientError, list ]);

    useEffect(() => {
        if (!list || holders?.length) {
            return;
        }
        const allocation = !asset.iroDenom ?
            (asset.network.totalSupply?.value.amount || 0) / (10 ** 18) :
            (Number(getIroPlan(asset.networkId)?.totalAllocation?.amount) || 0) / (10 ** 18);
        cancelAndSetHoldersPromise((signal) => getHolders(asset, allocation, signal))
            .then((holders) => {
                if (!asset.iroDenom) {
                    const amount = allocation - holders.reduce((current, holder) => current + holder.amount, 0);
                    const weight = amount * holders[0].weight / holders[0].amount;
                    holders.push({ amount, weight, hexAddress: '' });
                }
                holders.sort((holder1, holder2) => holder2.amount - holder1.amount);
                setHolders(holders);
            })
            .catch((error) => handleClientError(error))
            .finally(() => setLoading(false));
    }, [ asset, cancelAndSetHoldersPromise, getIroPlan, handleClientError, holders?.length, list ]);

    return { holders, totalCount, loading, countLoading };
};
