import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../../../../shared/components/badge/badge';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import Spinner from '../../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { timeToMilliseconds } from '../../../../shared/utils/date-utils';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { ReactComponent as SwapIcon } from '../../../../assets/icons/swap-horiz.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';
import DisplayNameWithFuture from '../../../currency/display-name-with-future/display-name-with-future';
import IncentiveBadge from '../../../incentives/incentive-badge/incentive-badge';
import { useIncentives } from '../../../incentives/incentives-context';
import { NetworkCommentsContextProvider } from '../../../network/network-comments/network-comments-context';
import PathNav, { PathNavItem } from '../../../path-nav/path-nav';
import TokensSwapDialog from '../../../amm/tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import { useAsset } from '../../asset-context';
import ImportTokenDialog from '../../import-token-dialog/import-token-dialog';
import AssetDashboard from '../asset-dashboard';
import './asset-dashboard-page.scss';

const AssetDashboardPage: React.FC = () => {
    const { assetMap, loading } = useAsset();
    const { incentivesState } = useIncentives();
    const { key } = useParams();
    const [ importTokenDialogOpen, setImportTokenDialogOpen ] = useState(false);
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);

    const asset = useMemo(() => !key ? undefined : assetMap?.[key], [ assetMap, key ]);

    const showInactiveLabel = useMemo(() => {
        return (asset?.network.status === 'Unavailable' || asset?.network.status === 'Degraded') &&
            (asset?.network.inactiveTime && asset.network.inactiveTime < Date.now() - timeToMilliseconds({ days: 7 }));
    }, [ asset?.network.inactiveTime, asset?.network.status ]);

    if (!asset && loading) {
        return <Spinner size='large' className='asset-dashboard-loader' />;
    }
    if (!asset) {
        return <h5 className='not-found-message'>Asset not found</h5>;
    }
    return <>
        <div className='asset-dashboard-page page'>
            <PathNav>
                <PathNavItem label='Liquidity' />
                <PathNavItem label='Assets' url='/amm/assets' />
                <PathNavItem label={asset.currency.displayDenom} />
            </PathNav>

            <div className='asset-header'>
                <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, asset.network)} alt='asset logo' />
                <h3 className='asset-name'>
                    <span className='asset-display-name'>
                        <DisplayNameWithFuture coins={asset} />
                        {incentivesState.incentives?.[asset.key]?.some((incentive) => incentive.coins.length) ?
                            <IncentiveBadge denom={asset.key} infoPlacement='bottom' /> : null}
                    </span>
                    <Link
                        className='asset-denom'
                        url={asset.network.type === 'Hub' ? '/dymension/metrics' : `/rollapps/${asset.networkId}`}
                        disabled={asset.network.type !== 'Hub' && asset.network.type !== 'RollApp'}
                    >
                        {asset.network.chainName}
                    </Link>
                </h3>

                {showInactiveLabel && (
                    <Badge
                        color={getCssVariableValue('--red-rgb').split(',').map(Number)}
                        className='inactive-badge'
                        label='RollApp has been inactive for over a week'
                    />
                )}

                <span className='space' />

                <div className='asset-dashboard-actions'>
                    <Button
                        className='asset-dashboard-action'
                        buttonType='secondary'
                        size='small'
                        onClick={() => setImportTokenDialogOpen(true)}
                    >
                        <WalletIcon />&nbsp;Add to Wallet
                    </Button>

                    <Button
                        className='asset-dashboard-action'
                        buttonType='primary'
                        size='small'
                        onClick={() => setTokenSwapDialogOpen(true)}
                    >
                        <SwapIcon />&nbsp;Swap
                    </Button>
                </div>
            </div>
            {asset.network.type !== 'RollApp' ? <AssetDashboard asset={asset} /> :
                <NetworkCommentsContextProvider network={asset.network}><AssetDashboard asset={asset} /></NetworkCommentsContextProvider>}
        </div>

        {tokenSwapDialogOpen && (
            <TokensSwapDialog toCoins={asset} onRequestClose={() => setTokenSwapDialogOpen(false)} />
        )}

        {importTokenDialogOpen && <ImportTokenDialog token={asset} onRequestClose={() => setImportTokenDialogOpen(false)} />}
    </>;
};

export default AssetDashboardPage;
