import { Decimal } from 'cosmjs/packages/math';
import { MsgUpdateDemandOrderEncodeObject } from 'cosmjs/packages/stargate';
import { MsgFulfillOrderEncodeObject } from 'cosmjs/packages/stargate/build/modules';
import { isNaN } from 'lodash';
import { readStream } from '../../../shared/utils/file-utils';
import { StationClient } from '../../client/station-clients/station-client';
import {
    convertToCoinsAmount,
    fetchPathAndDenom,
    getCurrency,
    getIbcSourceNetwork,
    getMaxDenomAmount,
    parseCoins,
} from '../../currency/currency-service';
import { CoinsAmount, Currency } from '../../currency/currency-types';
import { convertIroToCoinsAmount, isIroDenom } from '../../iro/iro-service';
import { ChannelNetworkMap, Network } from '../../network/network-types';
import {
    IBC_TRANSFER_FIELDS, IBC_TRANSFER_NUMBER_FIELDS,
    IbcBaseStatus,
    IbcSingleTransferDetailsResponse,
    IbcTransferDetails,
    IbcTransferDetailsResponse,
    IbcTransferStatus,
} from './ibc-status-types';

const INDEXER_MAX_PAGE_SIZE = 100;
const INDEXER_MAX_QUERIES = 10;

export const loadIbcTransfer = async (transferId: string, signal?: AbortSignal): Promise<IbcTransferDetails | undefined> => {
    const query = `{
        ibcTransferDetail(id: "${transferId}") {
            ${IBC_TRANSFER_FIELDS.join(' ')}
        }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        signal,
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcSingleTransferDetailsResponse);
    return fetchTransferFromResponse(ibcTransferDetails);
};

export const loadIbcTransfers = async (
    networkId: string,
    signal: AbortSignal,
    pageSize: number,
    page = 0,
    statuses?: IbcTransferStatus[],
    searchText?: string,
    channels?: string[],
    address?: string,
    haveEibcOrder?: boolean,
): Promise<{ totalCount: number, transfers: IbcTransferDetails[] }> => {
    const isHexHash = searchText?.replaceAll(/^0x/g, '').match(/^[0-9a-fA-F]{30,}$/)?.length;
    const hexText = isHexHash ? searchText?.replaceAll(/^0x/g, '') : '';
    const eibcOrderTime = new Date();
    eibcOrderTime.setHours(eibcOrderTime.getHours() - 180);
    const query = `{
        ibcTransferDetails(
            offset: ${page * pageSize},
            first: ${pageSize},
            orderBy: TIME_DESC,
            filter: {
                network: {equalTo: "${networkId}"}
                ${address ? `and: { or: [
                    {receiver: {equalToInsensitive: "${address}"}},
                    {sender: {equalToInsensitive: "${address}"}},
                ] }` : ''}
                ${haveEibcOrder ? `
                    eibcOrderId: { isNull: false }
                    eibcFee: { notEqualTo: "" }
                    time: { greaterThan: "${eibcOrderTime.getTime()}" }
                ` : ''}
                ${statuses ? `status: {in: [${statuses.filter((status) => status !== 'Initiated').join(',')}]}` : ''}
                ${searchText ? `or: [
                    ${searchText.length >= 3 && searchText.length <= 8 ? `{denom: {startsWithInsensitive: "${searchText}"}},` : ''}
                    ${!isHexHash && !isNaN(Number(searchText)) ? `{blockHeight: {equalTo: "${searchText}"}},` : ''}
                    ${searchText.length >= 30 ? `
                        {receiver: {equalToInsensitive: "${searchText}"}},
                        {sender: {equalToInsensitive: "${searchText}"}},
                    ` : ''}
                    ${isHexHash ? `
                        {hash: {equalToInsensitive: "${hexText}"}},
                        {hexReceiver: {equalToInsensitive: "0x${hexText}"}},
                        {hexSender: {equalToInsensitive: "0x${hexText}"}},
                    ` : ''}
                    ${channels?.length && channels.length < 50 ? `
                        {sourceChannel: {in: ["${channels.join('","')}"]}},
                        {destinationChannel: {in: ["${channels.join('","')}"]}},
                    ` : ''}
                ]` : ''}
            }
        ) {
            totalCount
            nodes { ${IBC_TRANSFER_FIELDS.join(' ')} }
        }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        signal,
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcTransferDetailsResponse);
    return fetchTransfersFromResponse(ibcTransferDetails);
};

export const loadInitiatedTransfers = async (networkId: string, signal: AbortSignal, address?: string): Promise<IbcTransferDetails[]> => {
    let response = await fetch(
        `${process.env.REACT_APP_GET_INITIALIZED_IBC_TRANSFER_DETAILS}?networkId=${networkId}&address=${address}`,
        { signal },
    );
    let responseText = response?.body ? await readStream(response.body) : undefined;
    const initiatedTransfers = JSON.parse(responseText || '[]') as IbcTransferDetails[];
    if (!initiatedTransfers.length) {
        return initiatedTransfers;
    }
    const query = `{
        ibcTransferDetails(filter: {id: { in: [${initiatedTransfers.map((transfer) => `"${transfer.id}"`)}] } }) {
            nodes { ${IBC_TRANSFER_FIELDS.join(' ')} }
        }
    }`;
    response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        signal,
    });
    responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcTransferDetailsResponse);
    const { transfers } = fetchTransfersFromResponse(ibcTransferDetails);
    if (!transfers.length) {
        return initiatedTransfers;
    }
    fetch(`${process.env.REACT_APP_REMOVE_INITIALIZED_IBC_TRANSFERS}?ids=${transfers.map((transfer) => transfer.id).join(',')}`, { signal })
        .then(() => console.log('Initiated transfers removed'))
        .catch((error) => console.error('Failed to remove initiated transfers', error));
    return initiatedTransfers.filter((initiatedTransfer) => !transfers.some((transfer) => transfer.id === initiatedTransfer.id));
};

export const loadFulfilledTransfers = async (hubId: string, address: string, signal?: AbortSignal): Promise<IbcTransferDetails[]> => {
    const transferFields: (keyof IbcTransferDetails)[] = [
        'sourceChannel',
        'destinationChannel',
        'denom',
        'amount',
        'time',
        'proofHeight',
        'packetType',
        'sequence',
        'eibcFee',
        'type',
        'status',
        'id',
    ];
    const totalTransfers: IbcTransferDetails[] = [];
    for (let currentPage = 0; currentPage < INDEXER_MAX_QUERIES; currentPage++) {
        const query = `{
            ibcTransferDetails(
                orderBy: TIME_ASC,
                offset: ${INDEXER_MAX_PAGE_SIZE * currentPage},
                first: ${INDEXER_MAX_PAGE_SIZE},
                filter: {
                    network: { equalTo: "${hubId}" },
                    or: [
                        { fulfillerAddress: { equalTo: "${address}" } },
                        { eibcLpAddress: { equalTo: "${address}" } }
                    ],
                    status: { in: [ FullFilled, RefundingFullFilled ] }
                }
            ) {
                totalCount,
                nodes { ${transferFields.join(' ')} }
            }
        }`;
        const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query }),
            signal,
        });
        const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
        const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcTransferDetailsResponse);
        const { transfers, totalCount } = fetchTransfersFromResponse(ibcTransferDetails, transferFields);
        totalTransfers.push(...transfers);
        if (totalTransfers.length >= totalCount) {
            break;
        }
    }
    return totalTransfers;
};

export const loadPendingTransfers = async (hubId: string, hubAddress: string, signal: AbortSignal): Promise<IbcTransferDetails[]> => {
    const transferFields: (keyof IbcTransferDetails)[] = [
        'sourceChannel',
        'destinationChannel',
        'denom',
        'amount',
        'time',
        'proofHeight',
        'packetType',
        'sequence',
        'status',
        'type',
        'id',
    ];
    const query = `{
        ibcTransferDetails(
            orderBy: TIME_ASC,
            filter: {
                network: { equalTo: "${hubId}" },
                or: [
                    { receiver: { equalTo: "${hubAddress}" }, status: { in: [ Pending, EibcPending ] } },
                    { sender: { equalTo: "${hubAddress}" }, status: { in: [ Refunding ] } }
                ],
                proofHeight: { notEqualTo: "0" },
                packetType: { notEqualTo: "" }
                sequence: { notEqualTo: 0 }
            }
        ) {
            nodes { ${transferFields.join(' ')} }
        }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        signal,
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcTransferDetailsResponse);
    return fetchTransfersFromResponse(ibcTransferDetails, transferFields).transfers;
};

export const getFilteredInitiatedTransfers = (
    transfers: IbcTransferDetails[],
    statuses?: IbcTransferStatus[],
    searchText?: string,
    channels?: string[],
): IbcTransferDetails[] => {
    return transfers.filter((transfer) => {
        if (statuses && !statuses.includes(transfer.status)) {
            return false;
        }
        if (channels?.length && !channels.includes(transfer.sourceChannel) && !channels.includes(transfer.destinationChannel)) {
            return false;
        }
        if (!searchText) {
            return true;
        }
        if (searchText.length >= 3 && searchText.length <= 8 && transfer.denom.includes(searchText)) {
            return true;
        }
        if (!isNaN(Number(searchText)) && transfer.blockHeight === Number(searchText)) {
            return true;
        }
        const isHexHash = searchText?.replaceAll(/^0x/g, '').match(/^[0-9a-fA-F]{30,}$/)?.length;
        const lowerCaseSearchText = searchText.toLowerCase();
        const hexText = isHexHash ? lowerCaseSearchText?.replaceAll(/^0x/g, '') : '';
        return searchText.length >= 30 && (transfer.searchable?.includes(lowerCaseSearchText) ||
            transfer.searchable?.includes(hexText) || transfer.hash.toLowerCase() === hexText);
    });
};

export const getTransferCoins = (
    sourceNetwork: Network,
    hubNetwork: Network,
    transferDenom: string,
    transferAmount: number,
    hubChannelNetworkMap: ChannelNetworkMap,
    networks: Network[],
): CoinsAmount | undefined => {
    if (!sourceNetwork) {
        return;
    }
    if (isIroDenom(transferDenom)) {
        return convertIroToCoinsAmount({ denom: transferDenom, amount: transferAmount.toString() }, networks) || undefined;
    }
    const { path, baseDenom } = fetchPathAndDenom(transferDenom);
    if (!path) {
        const currency = baseDenom && getCurrency(sourceNetwork, baseDenom);
        return currency ? { currency, amount: getMaxDenomAmount(transferAmount, currency), networkId: sourceNetwork.chainId } : undefined;
    }
    let ibcSourceNetwork: Network | undefined;
    let currency: Currency | undefined;
    let iroDenom: string | undefined;
    if (isIroDenom(baseDenom)) {
        const result = convertIroToCoinsAmount({ denom: baseDenom, amount: transferAmount.toString() }, networks) || undefined;
        ibcSourceNetwork = result?.networkId ? networks.find((network) => network.chainId === result.networkId) : undefined;
        currency = result?.currency;
        iroDenom = result?.iroDenom;
    } else {
        ibcSourceNetwork = getIbcSourceNetwork(sourceNetwork, hubNetwork, hubChannelNetworkMap, path);
        currency = baseDenom && ibcSourceNetwork ? getCurrency(ibcSourceNetwork, baseDenom) : undefined;
    }
    if (!currency || !ibcSourceNetwork) {
        return;
    }
    const ibc = { representation: '', path };
    const amount = getMaxDenomAmount(transferAmount, currency);
    return { amount, currency, ibc, networkId: ibcSourceNetwork.chainId, iroDenom };
};

export const getTransferEibcFeeCoins = (eibcFee: string, client: StationClient): Promise<CoinsAmount | null> => {
    const coins = parseCoins(eibcFee);
    return convertToCoinsAmount(coins[0], client);
};

export const getBaseStatus = (transfer: IbcTransferDetails): IbcBaseStatus => {
    switch (transfer.status) {
        case 'Success':
        case 'Sent':
        case 'FullFilled':
            return 'Success';
        case 'Failure':
        case 'RefundingFullFilled':
            return 'Failed';
        case 'Refunding':
            return 'Refunding';
    }
    return 'Pending';
};

export const getStatusesOfBaseStatus = (baseStatus: IbcBaseStatus): IbcTransferStatus[] => {
    switch (baseStatus) {
        case 'Success':
            return [ 'Success', 'Sent', 'FullFilled' ];
        case 'Failed':
            return [ 'Failure', 'RefundingFullFilled' ];
        case 'Refunding':
            return [ 'Refunding' ];
    }
    return [ 'Pending', 'EibcPending', 'Finalizing', 'Initiated' ];
};

export const getStatusTooltipInfo = (transfer: IbcTransferDetails): string => {
    switch (transfer.status) {
        case 'Initiated':
            return 'Transaction sent on the source chain but yet to arrive to Dymension';
        case 'Pending':
            return 'Transaction arrived to the destination chain but still not handled';
        case 'EibcPending':
            return 'Awaiting fast withdrawal eIBC order fulfillment';
        case 'Finalizing':
            return 'Standard IBC finalization period';
        case 'Sent':
            return 'Transaction successfully sent';
        case 'Success':
            return 'Transfer has completed successfully';
        case 'Refunding':
            return 'Transaction to RollApp timed out and awaiting refund fulfillment';
        case 'Failure':
            return 'Transfer failed';
        case 'FullFilled':
            return 'eIBC order successfully fulfilled';
        case 'RefundingFullFilled':
            return 'Refund for failed transfer successfully fulfilled';
    }
};

export const getStatusName = (status: IbcTransferStatus): string => {
    if (status === 'EibcPending') {
        return 'eIBC Pending';
    } else if (status === 'FullFilled') {
        return 'Fulfilled';
    } else if (status === 'RefundingFullFilled') {
        return 'Refunding Fulfilled';
    }
    return status;
};

const fetchTransfersFromResponse = (
    ibcTransferDetailsResponse: IbcTransferDetailsResponse,
    transferFields = IBC_TRANSFER_FIELDS,
): { transfers: IbcTransferDetails[], totalCount: number } => {
    if (!ibcTransferDetailsResponse.data?.ibcTransferDetails?.nodes) {
        return { totalCount: 0, transfers: [] };
    }
    const { totalCount, nodes } = ibcTransferDetailsResponse.data.ibcTransferDetails;
    const transfers = nodes.map((node) => transferFields.reduce((current, field) => {
        let value: string = node[field as keyof IbcTransferDetails];
        return { ...current, [field]: IBC_TRANSFER_NUMBER_FIELDS.includes(field) ? Number(value) : value };
    }, {} as IbcTransferDetails));
    return { transfers, totalCount };
};

const fetchTransferFromResponse = (
    ibcSingleTransferDetailsResponse: IbcSingleTransferDetailsResponse,
    transferFields = IBC_TRANSFER_FIELDS,
): IbcTransferDetails | undefined => {
    if (!ibcSingleTransferDetailsResponse.data.ibcTransferDetail) {
        return undefined;
    }
    const fields = ibcSingleTransferDetailsResponse.data.ibcTransferDetail as any;
    return transferFields.reduce((current, field) => {
        let value: string = fields[field as keyof IbcTransferDetails];
        return { ...current, [field]: IBC_TRANSFER_NUMBER_FIELDS.includes(field) ? Number(value) : value };
    }, {} as IbcTransferDetails);
};

export const createUpdateDemandOrderMessage = (transfer: IbcTransferDetails, coins: CoinsAmount): MsgUpdateDemandOrderEncodeObject => {
    let amount = BigInt(Decimal.fromUserInput(coins.amount.toFixed(coins.currency.decimals), coins.currency.decimals).atomics);
    if (coins.baseAmount && amount < coins.baseAmount) {
        amount = coins.baseAmount;
    }
    return {
        typeUrl: '/dymensionxyz.dymension.eibc.MsgUpdateDemandOrder',
        value: {
            newFee: amount.toString(),
            orderId: transfer.eibcOrderId,
            ownerAddress: transfer.receiver,
        },
    };
};

export const createFulfillOrderMessage = (transfer: IbcTransferDetails, address: string): MsgFulfillOrderEncodeObject => {
    const feeCoins = parseCoins(transfer.eibcFee || '');
    return {
        typeUrl: '/dymensionxyz.dymension.eibc.MsgFulfillOrder',
        value: {
            expectedFee: feeCoins?.[0]?.amount,
            orderId: transfer.eibcOrderId,
            fulfillerAddress: address,
        },
    };
};
