import { ApolloClient, gql, InMemoryCache, ObservableSubscription } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Observable } from '@apollo/client/utilities';
import { Decimal } from 'cosmjs/packages/math';
import { uniqBy } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubscriptionObserver } from 'zen-observable-ts';
import useVisibility from '../../shared/hooks/use-visibility';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { convertKeysToCamelCase } from '../../shared/utils/object-utils';
import { useHubNetworkState } from '../account/hub-network-state-context';
import { useAmm } from '../amm/amm-context';
import { Pool } from '../amm/types';
import { useIRO } from '../iro/iro-context';
import { TotalSupply } from '../network/statistics/analytics/network-analytics-types';
import { getFixedTradeBase } from '../trade/trade-service';
import { Trade } from '../trade/types';
import { Asset } from './asset-types';
import { getMainCurrency, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount, Currency } from '../currency/currency-types';
import { convertToBondingCurve, createIroDenom, fetchPlanTargetRaise, isIroDenom } from '../iro/iro-service';
import { useNetwork } from '../network/network-context';

// noinspection GraphQLUnresolvedReference
const TRADES_SUBSCRIPTION = gql`subscription TradesSubscription { trades(mutation: INSERT) { _entity } }`;

const gqlClient = new ApolloClient({
    link: new WebSocketLink({ uri: process.env.REACT_APP_SUBQUERY_URL.replace(/^(http)/, 'ws'), options: { reconnect: true } }),
    cache: new InMemoryCache(),
});

let tradesObserver: SubscriptionObserver<Trade>;

export const BLOCK_TIME = 5000;

export const tradesObservable = new Observable<Trade>((observer) => { tradesObserver = observer; });

const MARKET_CAP_LIQUIDITY_FACTOR =
    process.env.REACT_APP_ENV === 'mainnet' || process.env.REACT_APP_ENV === 'mainnet-staging' ? 1000 : 1000000;

interface AssetContextValue {
    assets?: Asset[];
    hubAsset?: Asset;
    vsAsset?: Asset;
    loading: boolean;
    assetMap?: { [assetKey: string]: Asset };
    mainAssetMap?: { [networkId: string]: Asset };
    getTokenPrice: (coins: CoinsAmount, vsCoins?: CoinsAmount, priceOfOneToken?: boolean) => number | undefined;
    getAssetLink: (asset: Asset | CoinsAmount) => string;
    toCalculatePriceWithMarketCap: (asset: Asset) => boolean;
    addAsset: (asset: Asset) => void;
}

export const AssetContext = createContext<AssetContextValue>({} as AssetContextValue);

export const useAsset = (): AssetContextValue => useContext(AssetContext);

export const AssetContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { hubNetwork, hubCurrency, networks, networkDenoms, getNetwork } = useNetwork();
    const { ammState, getPoolLiquidity, getTokenPrice: getAmmTokenPrice } = useAmm();
    const { getIroPlan, refreshIroPlans, iroPlans, loading: iroPlansLoading, getTokenPrice: getIroTokenPrice } = useIRO();
    const { address } = useHubNetworkState();
    const [ assets, setAssets ] = useState<Asset[]>();
    const [ newTrade, setNewTrade ] = useState<Trade>();
    const [ , setLastTradeTime ] = useState(0);
    const [ , setTradesSubscription ] = useState<ObservableSubscription>();
    const visibility = useVisibility();

    const loading = useMemo(
        () => Boolean(!assets || ammState.loading || ammState.paramsLoading || iroPlansLoading),
        [ ammState.loading, ammState.paramsLoading, assets, iroPlansLoading ],
    );

    const hubAsset = useMemo(() => assets?.find((asset) => asset.network.type === 'Hub'), [ assets ]);

    const vsAsset = useMemo(
        () => assets?.find((asset) => ammState.params && isCoinsEquals(asset, ammState.params.vsCoins)),
        [ ammState.params, assets ],
    );

    const toCalculatePriceWithMarketCap = useCallback((asset: Asset) => asset.currency.type === 'main' &&
        (asset.network.type === 'Hub' || asset.network.type === 'RollApp') && asset.network.status !== 'Unavailable', []);

    const getTokenPrice = useCallback((coins: CoinsAmount, vsCoins?: CoinsAmount, priceOfOneToken?: boolean) => {
        const network = getNetwork(coins.networkId);
        if (network?.status === 'IRO' || coins.iroDenom) {
            const amount = getIroTokenPrice(coins, priceOfOneToken);
            if (!hubNetwork) {
                return amount;
            }
            const hubCurrency = getMainCurrency(hubNetwork);
            return getAmmTokenPrice({ currency: hubCurrency, amount, networkId: hubNetwork.chainId }, vsCoins);
        }
        return getAmmTokenPrice(coins, vsCoins, priceOfOneToken);
    }, [ getAmmTokenPrice, getIroTokenPrice, getNetwork, hubNetwork ]);

    const updateAsset = useCallback((pool: Pool, assetIndex: 0 | 1, vsCoins: Currency, assets: Asset[]) => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        const poolAsset = pool.assets[assetIndex];
        const network = getNetwork(poolAsset.networkId);
        if (!network) {
            return;
        }
        const assetKey = `${network.chainId}/${poolAsset.currency.baseDenom}`;
        let currentAsset = assets.find((asset) => asset.key === assetKey);
        if (!currentAsset) {
            return;
        }
        if (!toCalculatePriceWithMarketCap(currentAsset)) {
            if (pool.liquidity?.previousDayValue) {
                const previousDayLiquidity = getMaxDenomAmount(pool.liquidity.previousDayValue.value || 0, vsCoins);
                const previousDayAssetAmount = getMaxDenomAmount(assetIndex === 0 ?
                    pool.liquidity.previousDayValue.asset1Amount : pool.liquidity.previousDayValue.asset2Amount, poolAsset.currency);
                currentAsset.previousDayPrice = (previousDayLiquidity / 2) / previousDayAssetAmount;
            }
            if (pool.liquidity?.previousHourValue) {
                const previousHourLiquidity = getMaxDenomAmount(pool.liquidity.previousHourValue.value || 0, vsCoins);
                const previousHourAssetAmount = getMaxDenomAmount(assetIndex === 0 ?
                    pool.liquidity.previousHourValue.asset1Amount : pool.liquidity.previousHourValue.asset2Amount, poolAsset.currency);
                currentAsset.previousHourPrice = (previousHourLiquidity / 2) / previousHourAssetAmount;
            }
            if (pool.liquidity?.firstValue) {
                const firstLiquidity = getMaxDenomAmount(pool.liquidity.firstValue.value || 0, vsCoins);
                const firstAssetAmount = getMaxDenomAmount(assetIndex === 0 ?
                    pool.liquidity.firstValue.asset1Amount : pool.liquidity.firstValue.asset2Amount, poolAsset.currency);
                currentAsset.firstPrice = (firstLiquidity / 2) / firstAssetAmount;
                if (!currentAsset.previousDayPrice) {
                    currentAsset.previousDayPrice = currentAsset.firstPrice;
                }
                if (!currentAsset.previousHourPrice) {
                    currentAsset.previousHourPrice = currentAsset.firstPrice;
                }
            }
        }
        currentAsset.pools.push(pool);
        currentAsset.volume +=
            getMaxDenomAmount((pool.volume?.value || 0) - (pool.volume?.previousDayValue || 0), vsCoins);
        currentAsset.previousDayVolume +=
            getMaxDenomAmount((pool.volume?.previousDayValue || 0) - (pool.volume?.previousTwoDaysValue || 0), vsCoins);

        currentAsset.liquidity += poolLiquidity;
        if (pool.liquidity) {
            currentAsset.previousDayLiquidity += getMaxDenomAmount(pool.liquidity.previousDayValue?.value || 0, vsCoins);
        }
        currentAsset.locked += ammState.totalLockedValues?.[pool.lpTokenDenom] || 0;
    }, [ ammState.totalLockedValues, getNetwork, getPoolLiquidity, toCalculatePriceWithMarketCap ]);

    const calculateStakingApr = useCallback((totalSupply: TotalSupply): number => {
        if (!totalSupply.bondedAmount) {
            return 0;
        }
        const inflationValue = convertDecimalToInt(totalSupply.inflation || 0) * 100;
        const communityTax = convertDecimalToInt(totalSupply.communityTax || 0);
        const proposerReward = convertDecimalToInt(totalSupply.proposerReward || 0);
        return (inflationValue * totalSupply.amount / totalSupply.bondedAmount) * (1 - communityTax - proposerReward);
    }, []);

    useEffect(() => {
        const vsCoins = ammState.params?.vsCoins.currency;
        if (!vsCoins || !ammState.pools || !iroPlans) {
            return;
        }
        const assets: Asset[] = [];
        networks.forEach((network) => network.currencies.forEach((currency) => {
            const networkDenom = networkDenoms?.find((networkDenom) =>
                networkDenom.baseDenom === currency.baseDenom && networkDenom.ibcNetworkId === network.chainId);
            if (currency.rollappIbcRepresentation ||
                (!networkDenom && network.type !== 'Hub' && network.status !== 'IRO')) {
                return;
            }
            let volume = currency.type === 'main' ? (network.volume?.value || 0) - (network.volume?.previousDayValue || 0) : 0;
            let previousDayVolume = currency.type === 'main' ?
                (network.volume?.previousDayValue || 0) - (network.volume?.previousTwoDaysValue || 0) : 0;
            if (network.type === 'Hub') {
                volume = networks.reduce((current, { volume }) => current + (volume?.value || 0) - (volume?.previousDayValue || 0), 0);
                previousDayVolume = networks
                    .reduce((current, { volume }) => current + (volume?.previousDayValue || 0) - (volume?.previousTwoDaysValue || 0), 0);
            }
            const asset: Asset = {
                network,
                currency,
                pools: [],
                key: `${network.chainId}/${currency.baseDenom}`,
                ibc: network.type === 'Hub' || network.status === 'IRO' ? undefined :
                    { path: networkDenom?.path || '', representation: networkDenom?.denom || '' },
                networkId: network.chainId,
                iroDenom: network.status === 'IRO' ? createIroDenom(network.chainId) : undefined,
                price: 0,
                previousHourPrice: 0,
                previousDayPrice: 0,
                liquidity: 0,
                stakingApr: 0,
                previousDayStakingApr: 0,
                volume: getMaxDenomAmount(volume, vsCoins),
                previousDayLiquidity: 0,
                firstPrice: 0,
                locked: 0,
                previousDayVolume: getMaxDenomAmount(previousDayVolume, vsCoins),
                amount: 0,
                iroProgress: 0,
                iroDymRaised: 0,
                invalidMarketCap: false,
            };
            asset.price = getTokenPrice(asset, undefined, true) || 0;
            if (toCalculatePriceWithMarketCap(asset)) {
                if (network.totalSupply?.previousDayValue) {
                    const previousDayMarketCap = getMaxDenomAmount(network.totalSupply.previousDayValue.marketCap || 0, vsCoins);
                    const previousDayTotalSupply = getMaxDenomAmount(network.totalSupply.previousDayValue.amount || 0, asset.currency);
                    asset.previousDayPrice = previousDayTotalSupply ? previousDayMarketCap / previousDayTotalSupply : 0;
                }
                if (network.totalSupply?.previousHourValue) {
                    const previousHourMarketCap = getMaxDenomAmount(network.totalSupply.previousHourValue.marketCap || 0, vsCoins);
                    const previousHourTotalSupply = getMaxDenomAmount(network.totalSupply.previousHourValue.amount || 0, asset.currency);
                    asset.previousHourPrice = previousHourTotalSupply ? previousHourMarketCap / previousHourTotalSupply : 0;
                }
                if (network.totalSupply?.firstValue) {
                    const firstMarketCap = getMaxDenomAmount(network.totalSupply.firstValue.marketCap || 0, vsCoins);
                    const firstTotalSupply = getMaxDenomAmount(network.totalSupply.firstValue.amount || 0, asset.currency);
                    asset.firstPrice = firstTotalSupply ? firstMarketCap / firstTotalSupply : 0;
                    if (!asset.previousDayPrice) {
                        asset.previousDayPrice = asset.firstPrice;
                    }
                    if (!asset.previousHourPrice) {
                        asset.previousHourPrice = asset.firstPrice;
                    }
                }
            }
            if (asset.network.totalSupply?.value) {
                asset.stakingApr = calculateStakingApr(asset.network.totalSupply.value);
            }
            if (asset.network.totalSupply?.previousDayValue) {
                asset.previousDayStakingApr = calculateStakingApr(asset.network.totalSupply?.previousDayValue);
            }
            const iroPlan = getIroPlan(network.chainId);
            if (network.status === 'IRO' && iroPlan) {
                asset.iroProgress = Number(iroPlan.soldAmt) * 100 / (Number(iroPlan.totalAllocation?.amount) || 1);
                asset.iroDymRaised = fetchPlanTargetRaise(iroPlan, asset.iroProgress);
                asset.liquidity = !hubNetwork || !hubCurrency ? 0 :
                    getTokenPrice({ amount: asset.iroDymRaised, currency: hubCurrency, networkId: hubNetwork.chainId }) || 0;
                asset.futureIRO = !iroPlan.startTime || new Date(iroPlan.startTime) > new Date();
            }
            assets.push(asset);
        }));
        ammState.pools.forEach((pool) => {
            updateAsset(pool, 0, vsCoins, assets);
            updateAsset(pool, 1, vsCoins, assets);
        });
        assets.filter(toCalculatePriceWithMarketCap).forEach((asset) => {
            if (!asset.network.totalSupply) {
                asset.invalidMarketCap = true;
            } else {
                const marketCap = asset.price * getMaxDenomAmount(asset.network.totalSupply.value.amount, asset.currency);
                asset.invalidMarketCap = marketCap > asset.liquidity * MARKET_CAP_LIQUIDITY_FACTOR;
            }
            if (asset.invalidMarketCap) {
                asset.previousDayPrice = 0;
                asset.previousHourPrice = 0;
            }
        });
        setAssets((currentAssets) => !currentAssets ? assets : uniqBy([ ...assets, ...currentAssets ], (asset) => asset.key));
    }, [
        toCalculatePriceWithMarketCap,
        ammState.params?.vsCoins.currency,
        ammState.pools,
        iroPlans,
        calculateStakingApr,
        getIroPlan,
        hubNetwork,
        getTokenPrice,
        networkDenoms,
        networks,
        updateAsset,
        hubCurrency,
    ]);

    const addAsset = useCallback((asset: Asset) => {
        setAssets((assets) => [ ...(assets || []), asset ]);
    }, []);

    const getAssetLink = useCallback((asset: Asset | CoinsAmount) => {
        const network = (asset as Asset).network || getNetwork(asset.networkId);
        if (!network || network.type === 'Hub') {
            return '/dymension/token';
        }
        if (network.type === 'RollApp' && asset.currency.type === 'main' && asset.networkId === network.chainId) {
            return `/rollapps/${network.chainId}/token`;
        }
        const assetKey = `${asset.networkId}/${asset.currency.baseDenom}`;
        return `/amm/asset/${encodeURIComponent(assetKey)}`;
    }, [ getNetwork ]);

    const updateAssetData = useCallback((trade: Trade) => {
        if (!assets || !hubAsset || !vsAsset || !trade.closingPrice) {
            return;
        }
        const fixedClosingPrice = trade.closingPrice * (10 ** (trade.tokenOut.currency.decimals - trade.tokenIn.currency.decimals));
        if (isCoinsEquals(trade.tokenIn, vsAsset)) {
            hubAsset.price = fixedClosingPrice;
        } else if (isCoinsEquals(trade.tokenOut, vsAsset)) {
            hubAsset.price = 1 / fixedClosingPrice;
        } else if (isCoinsEquals(trade.tokenIn, hubAsset)) {
            const asset = assets?.find((asset) => isCoinsEquals(asset, trade.tokenOut));
            if (asset) {
                asset.price = hubAsset.price * fixedClosingPrice;
            }
        } else {
            const asset = assets?.find((asset) => isCoinsEquals(asset, trade.tokenIn));
            if (asset) {
                asset.price = hubAsset.price / fixedClosingPrice;
            }
        }
        setAssets([ ...assets ]);
    }, [ assets, hubAsset, vsAsset ]);

    const updateIroData = useCallback((trade: Trade) => {
        if (trade.type !== 'IRO' || !trade.closingPrice || !trade.tokenInNetwork || !trade.tokenOutNetwork) {
            return;
        }
        const isBuy = isIroDenom(trade.tokenOutDenom);
        const iroPlan = getIroPlan(isBuy ? trade.tokenOutNetwork : trade.tokenInNetwork);
        if (!iroPlan?.bondingCurve) {
            return;
        }
        const closingPrice = isBuy ? trade.closingPrice : 1 / trade.closingPrice;
        const bondingCurve = convertToBondingCurve(iroPlan.bondingCurve);
        if (bondingCurve.M && bondingCurve.N) {
            const newSoldAmount = (closingPrice / bondingCurve.M) ** (1 / bondingCurve.N);
            iroPlan.soldAmt = Decimal.fromUserInput(newSoldAmount.toString(), 18).atomics;
        } else {
            iroPlan.soldAmt = (BigInt(iroPlan.soldAmt) + BigInt(isBuy ? trade.tokenOutAmount : -trade.tokenInAmount)).toString();
        }
        refreshIroPlans();
    }, [ getIroPlan, refreshIroPlans ]);

    useEffect(() => {
        if (newTrade && assets && ammState.params?.vsCoins) {
            const fixedTrade = getFixedTradeBase(newTrade, assets, ammState.params?.vsCoins.currency);
            if (fixedTrade) {
                tradesObserver?.next(fixedTrade);
                updateAssetData(fixedTrade);
                updateIroData(fixedTrade);
            }
            setNewTrade(undefined);
        }
    }, [ ammState.params?.vsCoins, assets, newTrade, updateAssetData, updateIroData ]);

    useEffect(() => {
        setTradesSubscription((subscription) => {
            subscription?.unsubscribe();
            if (!visibility) {
                return undefined;
            }
            const observable = gqlClient.subscribe<{ trades: { _entity: Trade } }>({ query: TRADES_SUBSCRIPTION });
            return observable.subscribe({
                next: (response) => {
                    const trade = response.data && convertKeysToCamelCase(response.data.trades._entity) as Trade;
                    if (!trade || trade.network !== hubNetwork?.chainId) {
                        return;
                    }
                    setLastTradeTime((lastTradeTime) => {
                        const minTradeTime = Math.max(Date.now(), lastTradeTime);
                        const timePassed = minTradeTime - trade.time;
                        const delay = trade.address === address ? 0 :
                            Math.round(Math.random() * Math.max(1000, BLOCK_TIME - timePassed) * 0.6);
                        setTimeout(() => setNewTrade(trade), minTradeTime - Date.now() + delay);
                        return minTradeTime + delay;
                    });
                },
                error: (error) => console.error('Trades subscription error: ', error),
            });
        });
    }, [ address, hubNetwork?.chainId, visibility ]);

    useEffect(() => () => setTradesSubscription((subscription) => {
        subscription?.unsubscribe();
        return undefined;
    }), []);

    const assetMap = useMemo(() => assets?.reduce<{ [assetKey: string]: Asset }>((current, asset) => {
        current[asset.key] = asset;
        return current;
    }, {}), [ assets ]);

    const mainAssetMap = useMemo(() => assets?.reduce<{ [networkId: string]: Asset }>((current, asset) => {
        if (asset.currency.type === 'main') {
            current[asset.networkId] = asset;
        }
        return current;
    }, {}), [ assets ]);

    return (
        <AssetContext.Provider
            value={{
                assets,
                assetMap,
                mainAssetMap,
                hubAsset,
                vsAsset,
                getAssetLink,
                loading,
                getTokenPrice,
                toCalculatePriceWithMarketCap,
                addAsset,
            }}
        >
            {children}
        </AssetContext.Provider>
    );
};
