import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/button/button';
import Input from '../../shared/components/form-controls/input/input';
import { Option } from '../../shared/components/form-controls/options-modal/options-modal';
import AccountMenu from '../account/account-menu/account-menu';
import { useAsset } from '../asset/asset-context';
import { Asset } from '../asset/asset-types';
import { getCurrencyLogoPath, getMainCurrency } from '../currency/currency-service';
import DisplayNameWithFuture from '../currency/display-name-with-future/display-name-with-future';
import DepositWithdraw from '../ibc-transfer/deposit-withdraw/deposit-withdraw';
import { getNetworkLogoPath } from '../network/network-service';
import { Network } from '../network/network-types';
import RollappStatusIndicator from '../rollapp/rollapp-status/indicator/rollapp-status-indicator';
import { ReactComponent as DymensionLogo } from '../../assets/logos/dymension-logo.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as UsdcLogo } from '../../assets/logos/usdc-logo.svg';
import { ReactComponent as MoneyBagIcon } from '../../assets/icons/money-bag.svg';
import NavBar, { NavBarItem } from '../../shared/components/nav-bar/nav-bar';
import Icon from '../../shared/components/icon/icon';
import { useAccountNetwork } from '../account/use-account-network';
import { useNetwork } from '../network/network-context';
import './top-bar.scss';

interface TopBarProps {
    className?: string;
}

const TopBar: React.FC<TopBarProps> = ({ className }) => {
    const navigate = useNavigate();
    const { rollapps, hubNetwork, getNetwork } = useNetwork();
    const { assets, assetMap } = useAsset();
    const { getAssetLink } = useAsset();
    const [ networkState, setNetwork ] = useAccountNetwork(undefined, true, true, true, { addressLoading: true });
    const [ depositWithdrawDialogOpen, setDepositWithdrawDialogOpen ] = useState(false);

    useEffect(() => {
        if (!networkState.network) {
            setNetwork(hubNetwork);
        }
    }, [ hubNetwork, networkState.network, setNetwork ]);

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const asset = assetMap?.[value];
        let { network, currency } = asset || {};
        if (!asset) {
            network = getNetwork(value.toString());
            currency = network && getMainCurrency(network);
        }
        if (!network || !currency) {
            return false;
        }
        return Boolean(
            searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId) || searchRegExp.test(currency.displayDenom));
    }, [ assetMap, getNetwork ]);

    const sortedAssets = useMemo(() => {
        return assets?.sort((asset1, asset2) => (asset2.liquidity || 0) - (asset1.liquidity || 0));
    }, [ assets ]);

    const accountMenuNetworks = useMemo(() => !hubNetwork ? [] : [
        hubNetwork.chainId,
        ...rollapps.filter((rollapp) => rollapp.status === 'Active' || rollapp.status === 'Degraded')
            .map((network) => network.chainId),
    ], [ hubNetwork, rollapps ]);

    const onSearchSuggestionClick = useCallback((key: string) => {
        const asset = assetMap?.[key];
        if (asset) {
            navigate(getAssetLink(asset));
        } else {
            navigate(`/rollapps/${key}/dashboard`);
        }
    }, [ assetMap, getAssetLink, navigate ]);

    const renderNetworkOption = (network?: Network): ReactNode => {
        if (!network) {
            return undefined;
        }
        const currency = getMainCurrency(network);
        return <>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
            <span className='network-name'>{network.chainName}</span>
            <span className='main-currency-container'>
                {currency?.displayDenom}
                <RollappStatusIndicator containerClassName='status-indicator-container' status={network.status} />
            </span>
            <span className='space' />
        </>;
    };

    const renderAssetOption = (asset?: Asset): ReactNode => {
        if (!asset) {
            return undefined;
        }
        return <>
            <img className='currency-logo' src={getCurrencyLogoPath(asset.currency, asset.network)} alt='currency-logo' />
            <div className='asset-name'><DisplayNameWithFuture coins={asset} /></div>
            <span className='asset-network-name'>{asset.network.chainName}</span>
        </>;
    };

    return (
        <div className={classNames('top-bar', className)}>
            <a href='/' className='logo'>
                <Icon><DymensionLogo /></Icon>
            </a>
            <NavBar className='nav-bar'>
                <NavBarItem label='RollApps' url='/rollapps' />
                <NavBarItem label='Dymension' url='/dymension'>
                    <NavBarItem label='Metrics' url='/dashboard' />
                    <NavBarItem label='DYM' url='/token' />
                    <NavBarItem label='Stake' url='/staking' />
                    <NavBarItem label='Endorse' url='/endorsement' />
                    <NavBarItem label='Vote' url='/governance' />
                </NavBarItem>
                <NavBarItem label='Transfer' url='/ibc'>
                    <NavBarItem label='Transfer' url='/transfer' />
                    <NavBarItem label='History' url='/status' />
                    <NavBarItem label='Bridge LP' url='/eibc-client' />
                </NavBarItem>
                <NavBarItem label='Trade' url='/amm'>
                    <NavBarItem label='Swap' url='/swap' />
                    <NavBarItem label='Pools' url='/pools' />
                    <NavBarItem label='Assets' url='/assets' />
                </NavBarItem>
                <NavBarItem label='Info'>
                    <NavBarItem label='Documentation' url='https://docs.dymension.xyz' external />
                    <NavBarItem label='Forum' url='https://forum.dymension.xyz' external />
                    <NavBarItem label='Explorer' url={hubNetwork?.explorerUrl || ''} disabled={!hubNetwork?.explorerUrl} external />
                    <NavBarItem label='Blog' url='https://medium.com/@dymension' external />
                </NavBarItem>
            </NavBar>

            <span className='space top-bar-middle-space' />

            <Input
                type='search'
                placeholder='Search...'
                className='rollapps-search'
                controlSize='small'
                searchFilterPredicate={searchFilterPredicate}
                emptySearchResultsLabel='No RollApps'
                onSuggestionSelect={(key) => onSearchSuggestionClick(key as string)}
            >
                {rollapps.map((rollapp) => (
                    <Option value={rollapp.chainId} key={rollapp.chainId} disabled={rollapp.disabled} group='RollApps'>
                        {renderNetworkOption(rollapp)}
                    </Option>
                ))}
                {sortedAssets?.map((asset) => (
                    <Option value={asset.key} key={asset.key} group='Assets'>
                        {renderAssetOption(asset)}
                    </Option>
                ))}
            </Input>

            <span className='space actions-space' />

            <div className='horizontally-centered actions-container'>
                <Button
                    size='small'
                    className='main-action'
                    buttonType='primary'
                    iconColorMode='original'
                    onClick={() => setDepositWithdrawDialogOpen(true)}
                >
                    <UsdcLogo /><span className='main-action-text'>Deposit</span>
                </Button>

                <Button buttonType='secondary' size='small' className='main-action' onClick={() => navigate('/earnings')}>
                    <MoneyBagIcon /><span className='main-action-text'>Earnings</span>
                </Button>

                <Button buttonType='secondary' size='small' className='main-action' onClick={() => navigate('/rollapps/manage')}>
                    <SettingsIcon /><span className='main-action-text'>Manage</span>
                </Button>

                <span className='space' />

                <AccountMenu
                    networkState={networkState}
                    menuAlign='right'
                    triggerSize='small'
                    optionalNetworks={accountMenuNetworks}
                    onNetworkSelect={(networkId) => setNetwork(getNetwork(networkId))}
                />
            </div>

            <DepositWithdraw dialogOpen={depositWithdrawDialogOpen} onDialogClose={() => setDepositWithdrawDialogOpen(false)} />
        </div>
    );
};

export default TopBar;
