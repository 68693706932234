import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEmptyCoinsAmount, getMainCurrency } from '../../currency/currency-service';
import DisplayNameWithFuture from '../../currency/display-name-with-future/display-name-with-future';
import { createIroDenom } from '../../iro/iro-service';
import { useNetwork } from '../network-context';
import { getNetworkLogoPath } from '../network-service';
import { Network } from '../network-types';
import './network-entry.scss';

interface NetworkEntryProps {
    network: Network;
    size?: 'small' | 'medium';
}

const NetworkEntry: React.FC<NetworkEntryProps> = ({ network, size = 'medium' }) => {
    const navigate = useNavigate();
    const { networkDenoms } = useNetwork();

    const coins = useMemo(() => ({
        ...createEmptyCoinsAmount(network, getMainCurrency(network), networkDenoms),
        iroDenom: network.status === 'IRO' ? createIroDenom(network.chainId) : undefined,
    }), [ network, networkDenoms ]);

    return (
        <div className={classNames('network-entry', size)}>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network logo' />
            <div className='network-title'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    className='network-name ellipsis'
                    onClick={() => navigate(network.type === 'Hub' ? '/dymension' : `/rollapps/${network.chainId}`)}
                >
                    {network.chainName}
                </a>
                <div className='network-token'><DisplayNameWithFuture coins={coins} /></div>
            </div>
        </div>
    );
};

export default NetworkEntry;
