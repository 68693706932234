import React, { useMemo } from 'react';
import Dialog, {
    DialogAction,
    DialogContent,
    DialogFooter,
    DialogProps,
    DialogTitle,
} from '../../../shared/components/dialog/dialog';
import { getFeeCurrency } from '../../currency/currency-service';
import { IbcTransferDetails } from '../../ibc-transfer/ibc-status/ibc-status-types';
import TransactionFee from '../../tx/transaction-fee/transaction-fee';
import { Fee } from '../../tx/tx-types';
import useHandleTxResponses from '../../tx/use-handle-tx-responses';
import { useWallet } from '../../wallet/wallet-context';
import BalancesList from '../balances-list/balances-list';
import { useHubNetworkState } from '../hub-network-state-context';
import { useClaimableTokens } from './use-claimable-tokens';
import './claimable-tokens-dialog.scss';

interface ClaimableTokensDialogProps extends DialogProps {
    transfers?: IbcTransferDetails[];
    loading?: boolean;
}

const ClaimableTokensDialog: React.FC<ClaimableTokensDialogProps> = ({ transfers, loading, ...otherDialogProps }) => {
    const { hubWallet } = useWallet();
    const networkState = useHubNetworkState();
    const { finalizedTransfers, txState, claim } = useClaimableTokens(transfers);
    useHandleTxResponses(txState, hubWallet, otherDialogProps.onRequestClose);

    const fee = useMemo((): Fee => {
        let feeValue = txState.fee?.coins;
        if (!feeValue && networkState.network) {
            const currency = getFeeCurrency(networkState.network);
            feeValue = { currency, amount: 0, networkId: networkState.network.chainId };
        }
        return { label: 'Transaction fee', loading: txState.feeLoading, value: feeValue };
    }, [ networkState.network, txState.fee?.coins, txState.feeLoading ]);

    return (
        <Dialog closable className='claimable-tokens-dialog' {...otherDialogProps}>
            <DialogTitle>Pending Transfers</DialogTitle>
            <DialogContent>
                <BalancesList
                    className='claimable-tokens-list section small'
                    balances={transfers || []}
                    loading={loading && !transfers}
                    onBalanceClick={otherDialogProps.onRequestClose}
                />
            </DialogContent>
            <DialogAction
                className='claim-action'
                primary
                disabled={txState.broadcasting || txState.feeLoading || !finalizedTransfers.length}
                loading={txState.broadcasting}
                onClick={claim}
            >
                Claim Transfers
            </DialogAction>
            <DialogFooter className='dialog-footer section small'><TransactionFee fee={fee} /></DialogFooter>
        </Dialog>
    );
};

export default ClaimableTokensDialog;
