import classNames from 'classnames';
import React, { useMemo } from 'react';
import CopyableItem from '../../../shared/components/copyable-item/copyable-item';
import Icon from '../../../shared/components/icon/icon';
import Link from '../../../shared/components/link/link';
import { getMainCurrency } from '../../currency/currency-service';
import { useDymns } from '../../dymns/dymns-context';
import { getNetworkLogoPath } from '../../network/network-service';
import { ReactComponent as FairLaunchTagIcon } from '../../../assets/icons/fair-launch-tag.svg';
import { Network } from '../../network/network-types';
import RollappStatusIndicator from '../rollapp-status/indicator/rollapp-status-indicator';
import './rollapp-summary.scss';

interface RollappNameProps {
    rollapp: Network;
    navigateOnClick?: boolean;
    showFairLaunch?: boolean;
    showStatusIndicator?: boolean;
    size?: 'medium' | 'big';
}

const RollappSummary: React.FC<RollappNameProps> = ({
    rollapp,
    navigateOnClick,
    showStatusIndicator = true,
    showFairLaunch,
    size = 'medium',
}) => {
    const { dymnsState } = useDymns();
    const mainCurrency = useMemo(() => getMainCurrency(rollapp), [ rollapp ]);

    return (
        <div className={classNames('rollapp-summary', size)}>
            <img className='network-logo' src={getNetworkLogoPath(rollapp)} alt='network-logo' />
            <div className='rollapp-details'>
                <div className='rollapp-name-container'>
                    <Link
                        inline
                        className={classNames('rollapp-name', { clickable: navigateOnClick })}
                        url={!navigateOnClick ? undefined : rollapp.type === 'Hub' ? '/dymension' : `/rollapps/${rollapp.chainId}`}
                    >
                        {rollapp.chainName}
                    </Link>
                    <span className='rollapp-info'>
                        {mainCurrency?.displayDenom || mainCurrency?.baseDenom || '[NO TOKEN]'}
                        {!showStatusIndicator ? <>&nbsp;&nbsp;</> :
                            <RollappStatusIndicator containerClassName='status-indicator-container' status={rollapp.status} />}
                        {showFairLaunch && rollapp.fairLaunch &&
                            <Icon
                                tooltip='Fair Launch - 100% of the supply allocated to IRO'
                                tooltipPlacement='right'
                                className='fair-launch-tag'
                            >
                                <FairLaunchTagIcon />
                            </Icon>}
                    </span>
                </div>
                <CopyableItem
                    className='rollapp-alias'
                    text={`@${dymnsState.aliasesMap[rollapp.chainId]?.aliases?.[0]}`}
                    dataName='Domain'
                    tooltipPlacement='top-start'
                />
            </div>
        </div>
    );
};

export default RollappSummary;
